import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useOtpDeactivate } from "../../../apiHooks/useAuth";
import { OtpInput } from "../../OtpInput/OtpInput";

type DeactivationBlockProps = {
    onDeactivate: () => void;
}

export const DeactivationBlock: React.FC<DeactivationBlockProps> = ({ onDeactivate }) => {
    const { isSuccess, isError, mutate: deactivate } = useOtpDeactivate();
    const [showOtp, setShowOtp] = useState(false);
    const [otp, setOtp] = useState('');
  
    useEffect(() => {
      if (otp.length === 6) {
        deactivate(otp);
      }
    }, [otp, deactivate]);

    useEffect(() => {
        if (isSuccess) {
            onDeactivate();
        }
    }, [isSuccess, onDeactivate]);

    return (
        <>
            <Typography mb={1}>You activated 2-Step Verification</Typography>
            {showOtp ? (
                <>
                    <Typography mt={2} mb={1}>Enter code to disable 2FA</Typography>
                    <OtpInput hasErrored={isError} numInputs={6} value={otp} onChange={setOtp} />
                </>
            ) : (
                <Button color="error" onClick={() => setShowOtp(true)}>Disable 2FA</Button>
            )}
        </>
    );
}