import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import { Link } from '../../Link/Link';
import { useLogin, useSocialLogin, useMfaLogin } from '../../../apiHooks/useToken';
import { useTokenVerify } from '../../../apiHooks/useAuth'
import React, { useEffect, useState } from 'react';
import { FormElement } from '../../../typings/form';
import { routes } from '../../../consts/routes';
import {
  BadRequestError,
} from '../../../apiHooks/configuration';
import { OtpInput } from '../../OtpInput/OtpInput';
import { JWTEphemeralToken } from '../../../openapi';
import { redirect } from '../../../utils/redirect';
import { SocialLoginButtons } from './SocialLoginButtons';

type LoginFormElement = FormElement<{
  email: string;
  password: string;
}>;

export const Login: React.FC = () => {
  const [error, setError] = useState('');
  const [otp, setOtp] = useState('');
  const { isSuccess } = useTokenVerify();
  const [ephemeralToken, setEphemeralToken] = useState('');
  const { error: otpError, isLoading: isOtpLoading, isError: isOtpError, mutate: sendOtp } = useMfaLogin();
  const handleEffemeralToken = (data: JWTEphemeralToken) => {
    setEphemeralToken(data.ephemeralToken)
  }

  const { isLoading, mutate: login } = useLogin(handleEffemeralToken);
  const { mutate: socialLogin } = useSocialLogin(handleEffemeralToken, setError);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setError('');
    const fields = (e.target as LoginFormElement).elements;

    login({
      email: fields.email?.value || '',
      password: fields.password?.value || '',
    }, {
      onError(err) {
        setError((err as BadRequestError).message || "Wrong password or email")
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      redirect(routes.editor);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (otp.length === 6) {
      sendOtp({ code: otp, ephemeralToken });
    }
  }, [otp, ephemeralToken, sendOtp])

  return (
    <Container component="main" maxWidth="xs">
      <Dialog open={!!ephemeralToken} onClose={() => setEphemeralToken('')}>
        <DialogTitle>Enter OTP code</DialogTitle>
        <DialogContent>
          <OtpInput isDisabled={isOtpLoading} hasErrored={isOtpError} numInputs={6} onChange={setOtp} value={otp} />
          {isOtpLoading && <Box mt={1}><CircularProgress /></Box>}
          {isOtpError && (
            <Typography color={error}>
              {(otpError as BadRequestError).message}
            </Typography>
          )}
        </DialogContent>
      </Dialog>
      <Box
        marginTop={8}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate mt={1}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            sx={{ display: 'none' }}
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <SocialLoginButtons onError={setError} onLogin={socialLogin} />
          {error && (
            <Alert icon={false} color="error" onClose={() => setError('')}>
              {error}
            </Alert>
          )}
          <Button
            disabled={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Sign in
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to={routes.resetPasswordByEmail} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to={routes.registration} variant="body2">
                Don’t have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
