import { Alert, Box, Button, Container, TextField, Typography } from "@mui/material"
import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { BadRequestError } from "../../../apiHooks/configuration";
import { useResetPasswordConfirm } from "../../../apiHooks/useToken";
import { routes } from "../../../consts/routes";
import { FormElement } from "../../../typings/form";

type ResetPasswordFormElement = FormElement<{
    password: string;
    repeatPassword: string;
  }>;

export const ResetPassword = () => {
    const { token, uid } = useParams();
    const [error, setError] = useState('');
    const { mutate: resetPassword, isLoading, isSuccess } = useResetPasswordConfirm();

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        setError('');
        const fields = (e.target as ResetPasswordFormElement).elements;

        if (!token) {
            setError('Token not specified');
            return;
        }
        if (!uid) {
            setError('UID not specified');
            return;
        }

        resetPassword({
            newPassword1: fields.password?.value || '',
            newPassword2: fields.repeatPassword?.value || '',
            uid,
            token
        }, {
          onError(err) {
            setError((err as BadRequestError).message || "Something went wrong")
          },
        });
      };

    if (isSuccess) {
        return <Navigate to={routes.login} />
    }
    
    return (
        <Container component="main" maxWidth="xs">
            <Box
                marginTop={8}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography component="h1" variant="h5">
                    Reset password
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate mt={1}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="repeatPassword"
                        label="Repeat password"
                        type="password"
                        id="repeatPassword"
                        autoComplete="current-password"
                    />
                    {error && (
                        <Alert icon={false} color="error" onClose={() => setError('')}>
                            {error}
                        </Alert>
                    )}
                    <Button
                        disabled={isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2, mb: 2 }}
                    >
                        Reset password
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}