import { useMutation, useQuery, UseMutationResult } from 'react-query';
import { TTSVoice, TtsApi, TTSTask } from '../openapi';
import { BadRequestError, getConfiguration } from './configuration';

const VOICES_API_KEY = 'voices';
const TTS_TASK_API_KEY = 'ttsTask';

const api = new TtsApi(getConfiguration(true));

export const useVoices = (onSuccess: (data: TTSVoice[]) => void) =>
  useQuery(VOICES_API_KEY, ({ signal }) => api.ttsVoicesList({ limit: 60 }, { signal }), {
    select: (data) => data.results || [],
    onSuccess,
  });

type UseTtsTaskCreate = (
  params: { voiceId: number; text: string },
  onSuccess?: (data: TTSTask) => void,
) => UseMutationResult<TTSTask, unknown, void, unknown>;

export const useTtsTaskCreate: UseTtsTaskCreate = (
  { voiceId, text },
  onSuccess,
) =>
  useMutation<TTSTask, BadRequestError>(
    TTS_TASK_API_KEY,
    () =>
      api.ttsTasksCreate({
        tTSTaskRequest: { voice: { id: voiceId }, text },
      }),
    {
      onSuccess,
    },
  );

export const useTtsTask = (id?: number) =>
  useQuery(
    [TTS_TASK_API_KEY, id],
    ({ signal }) => (id ? api.ttsTasksRetrieve({ id }, { signal }) : null),
    {
      enabled: !!id,
      refetchInterval: (data) =>
        data?.status !== 'success' && data?.status !== 'error' ? 1000 : false,
    },
  );
