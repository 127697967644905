import { SlideForm, TtsType } from "../components/pages/Editor/EditorContext/reducer";

export function prepareRequestSlides(slides: SlideForm[]) {
  return slides.map((slide) => {
    const audioSource =
      slide.sourceType === TtsType.Audio
        ? {
            file: slide.audioSource.file,
          }
        : {
            text: slide.audioSource.text,
            voice: slide.audioSource.voice,
          };

    const params = slide.layers[0] || {};
	  const { x = 0, y = 0 } = params;

		return {
			...slide,
			layers: [{
			  ...params,
			  x: Math.round(x * 1000) / 1000,
			  y: Math.round(y * 1000) / 1000,
			}],
			audioSource,
		};
	});
}
