import React from 'react';
import { Box, IconButton, Tab, Tabs } from '@mui/material';
import { AudioCard } from '../../AudioCard/AudioCard';
import { Delete } from '@mui/icons-material';
import { UploadButton } from '../../UploadButton/UploadButton';
import { AudioSourceRequest } from '../../../openapi';
import { useAudioFile, useCreateAudio } from '../../../apiHooks/useFiles';
import { useAuthUser } from '../../../apiHooks/useAuth';
import { TtsType } from './EditorContext/reducer';
import { TextArea } from './TextArea';

interface AudioSourcePickerProps {
  audioSource: AudioSourceRequest;
  sourceType: TtsType;
  onTypeChange: (sourceType: TtsType) => void;
  onChange: (audioSource: AudioSourceRequest) => void;
}

export const AudioSourcePicker: React.FC<AudioSourcePickerProps> = ({
  audioSource,
  sourceType,
  onTypeChange,
  onChange,
}) => {
  const { data: authUser } = useAuthUser();
  const {
    mutate: createAudio,
    reset,
    ...createAudioRequest
  } = useCreateAudio((data) => {
    onChange({
      ...audioSource,
      file: {
        id: data!.id!,
      },
    });
    reset();
  });
  const { data: audioFile } = useAudioFile(audioSource.file?.id);
  
  return (
    <>
      <Box my={3}>
        <Tabs
          value={sourceType}
          onChange={(e, value) => onTypeChange(value)}
          aria-label="basic tabs example"
        >
          <Tab label="Text" value={TtsType.Text} />
          {authUser?.isAudioAllowed && (
            <Tab label="Audio" value={TtsType.Audio} />
          )}
        </Tabs>
      </Box>
      {sourceType === TtsType.Text && (
        <TextArea
          audioSource={audioSource}
          onChange={onChange}
        />
      )}
      {sourceType === TtsType.Audio && (
        <Box display="flex" flexDirection="column" mb={3}>
          {audioFile && (
            <AudioCard
              action={
                <IconButton
                  onClick={() => {
                    onChange({
                      ...audioSource,
                      file: null,
                    });
                  }}
                >
                  <Delete />
                </IconButton>
              }
              name={audioFile.name}
              url={audioFile.file}
            />
          )}
          {!audioFile && (
            <UploadButton
              disabled={createAudioRequest.isLoading}
              onChange={(e) => {
                if (e.target.files?.[0]) {
                  createAudio(e.target.files?.[0]);
                }
              }}
            >
              Upload your audio
            </UploadButton>
          )}
        </Box>
      )}
    </>
  );
};
