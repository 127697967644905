import { Alert, Box, Button, Container, TextField, Typography } from "@mui/material"
import { useRef, useState } from "react";
import { BadRequestError } from "../../../apiHooks/configuration";
import { useResetPassword } from "../../../apiHooks/useToken";
import { FormElement } from "../../../typings/form";
import { Recaptcha, RecaptchaRef } from "../../Recaptcha/Recaptcha";

type RequestNewPasswordFormElement = FormElement<{
    email: string;
}>;

export const RequestNewPassword = () => {
    const recaptchaRef = useRef<RecaptchaRef>(null);
    const [error, setError] = useState('');
    const { mutate: resetPassword, isLoading, isSuccess } = useResetPassword();

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        setError('');
        const fields = (e.target as RequestNewPasswordFormElement).elements;
        const captcha = await recaptchaRef.current?.getToken();

        if (!captcha) {
            setError('Captcha not solved');
            return;
        }

        resetPassword({
            email: fields.email?.value || '',
            captcha
        }, {
            onError(err) {
                setError((err as BadRequestError).message || "Something went wrong")
            },
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                marginTop={8}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography component="h1" variant="h5">
                    Request reset link
                </Typography>
                {isSuccess ? (
                    <Box>
                        <Typography>We sent a link to reset password to your email</Typography>
                    </Box>
                ) : (
                    <Box component="form" onSubmit={handleSubmit} noValidate mt={1} minWidth="320px">
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        {error && (
                            <Alert icon={false} color="error" onClose={() => setError('')}>
                                {error}
                            </Alert>
                        )}
                        <Recaptcha ref={recaptchaRef} action="reset" />
                        <Button
                            disabled={isLoading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2 }}
                        >
                            Send verification link
                        </Button>
                    </Box>
                )}
            </Box>
        </Container>
    )
}