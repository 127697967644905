import { useMemo } from "react";
import development from "./development";
import stage from "./stage";
import production from "./prod";

export type Config = {
	features: Record<"COLUMN_LAYOUT", boolean>;
};


const config: Config = {
	features: {
		COLUMN_LAYOUT: false
	},
};

const mergeConfig = (config2: Partial<Config>) => ({
	...config,
	...config2,
	features: {
		...config.features,
		...(config2.features || {}),
	},
});

const getEnvFromHost = () => {
	if (window.location.host === "localhost:3000") {
		return "development";
	}
	if (window.location.host === "stage.spiritme.tech") {
		return "stage";
	}
	if (window.location.host === "studio.spiritme.tech") {
		return "production";
	}
	return "";
};

export const getConfig = (): Config => {
	const env = getEnvFromHost();

	if (env === "development") {
		return mergeConfig(development);
	}
	if (env === "stage") {
		return mergeConfig(stage);
	}
	if (env === "production") {
		return mergeConfig(production);
	}
	return config;
};

export const useConfig = () => useMemo(() => getConfig(), []);