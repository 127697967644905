import {
  Alert,
  Box,
  Grid,
  Skeleton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Layout } from '../../Layout/Layout';
import { GalleryList } from '../Gallery/GalleryList';
import { checkNotificationSupport } from '../../../utils/checkNotificationsSupport';
import { NotificationModal } from './NotificationModal';
import { useToggle } from '../../../hooks/useToggle';
import { useCreateVideo, useVideos } from '../../../apiHooks/useVideos';
import { BadRequestError } from '../../../apiHooks/configuration';
import { SlideEditor } from './SlideEditor';
import { SlidePicker } from './SlidePicker';
import { ResolutionEditor } from './ResolutionEditor';
import { useAvatars } from '../../../apiHooks/useAvatars';
import { prepareRequestSlides } from '../../../utils/editor';
import { EditorContext } from './EditorContext/EditorContext';
import { SlideForm } from './EditorContext/reducer';
import { VideoStatusEnum, ViewTypeEnum } from '../../../openapi';
import { EditorSettings } from './EditorSettings';
import { CharacterParams, Scene, SceneRef } from './Scene';
import { useMediaFiles } from '../../../apiHooks/useFiles';
import { getConfig } from '../../../consts/config';
import { background } from '../../../consts/colors';
import { gaEventGenerateVideo } from '../../../utils/googleAnalytics';
import { GenerateBlock } from './GenerateBlock';

type EditorPageProps = {
  isMobile?: boolean;
}

const config = getConfig();

export const EditorPage: React.FC<EditorPageProps> = ({ isMobile }) => {
  const sceneRef = useRef<SceneRef | null>(null);
  const { data: mediaFiles } = useMediaFiles();

  const {
    state: {
      requestData,
      currentSlide: selectedSlide
    },
    init: initState,
    setResolution,
    setName,
    moveSlide,
    setSelectedSlide,
    deleteSlide,
    createSlide,
    updateBackground,
    updateAudio,
    updateLayer,
  } = useContext(EditorContext);

  const { data: avatars } = useAvatars((data) => {
    initState({ avatar: data[0], voiceId: 0 })
  });

  const [waitList, setWaitList] = useState<number[]>([]);
  const [notify, setNotify] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const notificationModal = useToggle();

  const currentSlide = useMemo(
    () => requestData.slides.find((s: SlideForm) => s.id === selectedSlide),
    [requestData.slides, selectedSlide],
  );

  const currentLayer = currentSlide?.layers?.[0];

  const currentBackground = useMemo(
    () => mediaFiles?.find((i) => i.id === currentSlide?.background?.id),
    [currentSlide?.background, mediaFiles],
  );

  const avatar = useMemo(() => avatars?.find(
    (a) => {
      const slide = requestData?.slides?.find((s: SlideForm) => s.id === selectedSlide);

      return slide?.layers?.[0]?.avatar?.id === a.id;
    }
  ), [requestData?.slides, avatars, selectedSlide]);

  const { data: videosData } = useVideos();

  const {
    mutate: createVideo,
    reset,
    isLoading,
  } = useCreateVideo(
    (data) => {
      setWaitList((prev) => [...prev, data.id]);
      initState({
        avatar: avatar!,
        voiceId: requestData.slides[0].audioSource.voice?.id ?? 0
      });
    },
    (err) => {
      if (err instanceof BadRequestError) {
        if (err.type === 'NOT_ENOUGH_CREDITS') {
          setErrorMessage(err.details[0]);
        } else {
          setErrorMessage(err.message);
        }
      }
      reset();
    },
  );

  const handleGenerate = () => {
    const slides = prepareRequestSlides(requestData.slides);
    gaEventGenerateVideo();
    createVideo({
      ...requestData,
      slides,
    });
    notificationModal.turnOn();
  };

  const handleAvatarParamsChange = (layer: CharacterParams) => {
    if (!currentSlide) {
      return;
    }
    updateLayer({
      slideId: currentSlide?.id,
      layer
    })
  };

  useEffect(() => {
    const notRendered = waitList.filter(
      (id) =>
        !videosData?.find(
          (i) =>
            i.id === id &&
            (i.status === VideoStatusEnum.Success ||
              i.status === VideoStatusEnum.Error ||
              i.status === VideoStatusEnum.InsufficientBalanceError),
        ),
    );

    if (waitList.length !== notRendered?.length) {
      setWaitList(notRendered || []);
    }
    if (waitList.length > notRendered?.length) {
      if (checkNotificationSupport()) {
        // eslint-disable-next-line no-new
        new Notification('SpirirtMe', {
          body: 'Your video is ready',
          requireInteraction: true,
        });
      }
    }
  }, [videosData, waitList]);

  return (
    <Layout withAppBar={!isMobile}>
      {config.features.COLUMN_LAYOUT ? (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} position={{ xs: "sticky", lg: "initial" }} zIndex="2" top="0">
            <Box position="sticky" top="0" sx={{ backgroundColor: background }}>
              <SlidePicker
                  moveSlide={(dragIndex, hoverIndex) => moveSlide({ dragIndex, hoverIndex })}
                  selected={selectedSlide}
                  slides={requestData.slides}
                  selectSlide={setSelectedSlide}
                  deleteSlide={(id, selectedId) => deleteSlide({ id, selectedId })}
                  createSlide={createSlide}
              />
              {avatar && (
                <Scene
                  ref={sceneRef}
                  avatarPosition={
                    currentLayer?.viewType || ViewTypeEnum.Rectangular
                  }
                  avatar={avatar}
                  resolution={requestData.resolution}
                  background={currentBackground?.thumbnail}
                  onChange={handleAvatarParamsChange}
                  defaultParams={currentSlide?.layers[0] as CharacterParams}
                />
              )}
              {!avatar && (
                <Skeleton>
                  <Box width={{ xs: 320, md: 640}} height={{ xs: 180, md: 360 }} />
                </Skeleton>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <EditorSettings
              onGenerate={handleGenerate}
              onNotifyToggle={setNotify}
              isNotifyEnabled={notify}
              isGenerating={isLoading}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Stack spacing={2}>
            <Typography variant="h5" mb={2}>
              Set video settings
            </Typography>
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              value={requestData.name || ''}
              onChange={(e) =>
                setName(e.target.value)
              }
            />
          </Stack>
          <Box mt={2} mb={2}>
            <ResolutionEditor
              resolution={requestData.resolution}
              onChange={setResolution}
              fitAvatar={() => {
                setResolution({
                  width: avatar?.frameWidth ?? 1920,
                  height: avatar?.frameHeight ?? 1080,
                })
              }}
            />
          </Box>
          <Stack spacing={2}>
            <SlidePicker
              moveSlide={(dragIndex, hoverIndex) => moveSlide({ dragIndex, hoverIndex })}
              selected={selectedSlide}
              slides={requestData.slides}
              selectSlide={setSelectedSlide}
              deleteSlide={(id, selectedId) => deleteSlide({ id, selectedId })}
              createSlide={createSlide}
            />
            {currentSlide && (
              <SlideEditor
                avatars={avatars}
                slide={currentSlide}
                resolution={requestData.resolution}
                onLayerChange={updateLayer}
                onBackgroundChange={updateBackground}
                onAudioChange={updateAudio}
              />
            )}
          </Stack>
          <GenerateBlock 
            isLoading={isLoading}
            onGenerate={handleGenerate}
            isNotifyEnabled={notify}
            onNotifyToggle={setNotify}
          />
        </>
      )}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ marginTop: 6 }}
        onClose={() => {
          reset();
          setErrorMessage('');
        }}
      >
        <Alert
          onClose={() => setErrorMessage('')}
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      {notify && (
        <NotificationModal
          isOpen={notificationModal.enabled}
          onClose={notificationModal.turnOff}
        />
      )}
      <GalleryList isPolling={!!waitList.length} />
    </Layout>
  );
};
