import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { checkNotificationSupport } from '../../../utils/checkNotificationsSupport';

type NotificationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CHROME_LINK = 'https://support.google.com/chrome/answer/95472';
const FIREFOX_LINK =
  'https://support.mozilla.org/en-US/kb/push-notifications-firefox';

type Status = typeof Notification.permission | 'notSupported';

const Message: React.FC<{ status?: Status }> = ({ status }) => {
  if (status === 'notSupported') {
    return (
      <DialogContentText>
        Your browser doesn’t support notifications.
      </DialogContentText>
    );
  }
  if (status === 'denied') {
    return (
      <DialogContentText>
        Notifications are forbidden in your browser. You need to allow it in
        settings. Instructions for <a href={CHROME_LINK}>Google Chrome</a> and{' '}
        <a href={FIREFOX_LINK}>Mozilla Firefox</a>.
      </DialogContentText>
    );
  }
  if (status === 'granted') {
    return (
      <DialogContentText>
        We will send push notification when video is ready. Please, don’t close
        a window.
      </DialogContentText>
    );
  }

  return (
    <DialogContentText>
      Please, allow browser notifications. And we will send you a notification
      when your video is ready.
    </DialogContentText>
  );
};

export const NotificationModal: React.FC<NotificationModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [status, setStatus] = useState<Status>();

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (!checkNotificationSupport()) {
      setStatus('notSupported');
      return;
    }

    Notification.requestPermission().then(() => {
      setStatus(Notification.permission);
    });
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Notifications</DialogTitle>
      <DialogContent>
        <Message status={status} />
      </DialogContent>
    </Dialog>
  );
};
