import { useCallback, useState } from 'react';

export const useToggle = (defaultValue = false) => {
  const [enabled, setEnabled] = useState(defaultValue);

  const toggle = useCallback(() => {
    setEnabled((value) => !value);
  }, []);

  const turnOff = useCallback(() => {
    setEnabled(false);
  }, []);

  const turnOn = useCallback(() => {
    setEnabled(true);
  }, []);

  return {
    enabled,
    toggle,
    turnOff,
    turnOn,
  };
};
