import React, { AnchorHTMLAttributes, forwardRef, ReactNode } from 'react';

interface ExternalLinkProps extends AnchorHTMLAttributes<Element> {
  children: ReactNode;
}

export const ExternalLink = forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  ({ children, ...props }, ref) => (
    <a {...props} ref={ref}>
      {children}
    </a>
  ),
);
