import { useEffect, useState } from 'react';

const loaded: string[] = [];

export const useScript = (url: string) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (loaded.includes(url)) {
            setIsLoaded(true);
            return;
        }
        const script = document.createElement('script');

        script.src = url;
        script.async = true;
        script.onload = () => {
            loaded.push(script.src);
            setIsLoaded(true);
        };

        document.body.appendChild(script);
    }, [url]);

    return {
        isLoaded
    }
};
