import { PauseRounded, PlayArrowRounded } from '@mui/icons-material';
import { IconButton, Paper, styled, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

const StyledContainer = styled(Paper)`
  display: flex;
  justify-content: space-between;
`;

type AudioCardProps = {
  name?: string | null;
  url: string;
  action?: React.ReactElement;
};

export const AudioCard: React.FC<AudioCardProps> = ({ action, name, url }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const audioRefCurrent = audioRef.current;

    if (!audioRefCurrent) {
      return;
    }

    const handlePlay = () => {
      setIsPlaying(true);
    };
    const handlePause = () => {
      setIsPlaying(false);
    };

    audioRefCurrent.addEventListener('play', handlePlay);
    audioRefCurrent.addEventListener('pause', handlePause);
    audioRefCurrent.addEventListener('stop', handlePause);

    // eslint-disable-next-line consistent-return
    return () => {
      audioRefCurrent.removeEventListener('play', handlePlay);
      audioRefCurrent.removeEventListener('pause', handlePause);
      audioRefCurrent.removeEventListener('stop', handlePause);
    };
  }, []);

  return (
    <StyledContainer>
      <IconButton
        aria-label={isPlaying ? 'pause' : 'play'}
        onClick={() =>
          isPlaying ? audioRef.current?.pause() : audioRef.current?.play()
        }
      >
        {isPlaying ? <PauseRounded /> : <PlayArrowRounded />}

        <Typography ml={3}>{name}</Typography>
      </IconButton>
      {action}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} src={url} />
    </StyledContainer>
  );
};
