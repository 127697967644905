import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { logger } from "../../utils/logger";

export const ErrorElement = () => {
    const error = useRouteError();

    useEffect(() => {
        logger('error', error);
    }, [error])

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography textAlign="center">Something went wrong, please try to reload page</Typography>
            <Button onClick={() => window.location.reload()}>Reload</Button>
        </Box>
    )
}
