import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link as MuiLink,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { usePublishVideo, useVideos } from '../../../apiHooks/useVideos';
import { useToggle } from '../../../hooks/useToggle';
import { routes } from '../../../consts/routes';
import { VideoStatusEnum } from '../../../openapi';
import { Link } from 'react-router-dom';

type GalleryListProps = {
  isPolling?: boolean;
};

const { locale } = Intl.DateTimeFormat().resolvedOptions();
const dateFormatter = new Intl.DateTimeFormat(locale, {
  dateStyle: 'long',
  timeStyle: 'short',
  timeZoneName: undefined,
});

export const GalleryList: React.FC<GalleryListProps> = ({ isPolling }) => {
  const [hasExecuting, setHasExecuting] = useState(false);
  const [popupEl, setPopupEl] = useState<HTMLSpanElement | null>(null);
  const { data } = useVideos(isPolling || hasExecuting);
  const modal = useToggle();
  const { mutate: publish, data: publishData } = usePublishVideo(modal.turnOn);

  const isPopupOpen = Boolean(popupEl);

  const publicUrl = useMemo(
    () =>
      `${window.location.protocol}//${
      window.location.host
      }${routes.publicVideo.replace(':id', publishData?.uuid ?? '')}`,
    [publishData?.uuid],
  );

  useEffect(() => {
    if (data?.find(i => i.status === VideoStatusEnum.Executing)) {
      setHasExecuting(true);
    } else {
      setHasExecuting(false);
    }
  }, [data]);

  return data ? (
    <Box maxWidth="500px">
      <Dialog open={modal.enabled} onClose={modal.turnOff}>
        <DialogTitle>Share your video</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <MuiLink target="_blank" href={publicUrl}>
              {publicUrl}
            </MuiLink>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={modal.turnOff}>Close</Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h6">Videos</Typography>
      {(isPolling || hasExecuting) && (
        <Box>
          <CircularProgress />
        </Box>
      )}
      {!data.length && (
        <Typography>No videos</Typography>
      )}
      <List>
        {data.map((item) => (
          <ListItem
            key={item.id}
          >
            <ListItemText
              primary={
                item.result ? (
                  <MuiLink target="_blank" href={item.result}>
                    <>
                      {item.name}{' '}
                      {item.status === VideoStatusEnum.Success
                        ? dateFormatter.format(item?.cd)
                        : ''}
                    </>
                  </MuiLink>
                ) : (
                  item.name
                )
              }
              secondary={item.status === VideoStatusEnum.InsufficientBalanceError ? (
                <MuiLink sx={{ cursor: "pointer" }} onClick={e => setPopupEl(e.currentTarget)}>Run out of credits</MuiLink>
              ) : item.status}
            />
            {item.status === VideoStatusEnum.Success && (
              <ListItemButton onClick={() => publish(item.id)}>
                Publish video
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>

      <Popover
        open={isPopupOpen}
        anchorEl={popupEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setPopupEl(null)}
      >
        <Box p={2} maxWidth="100%" width="400px">
          <Box mb={1} display="flex" justifyContent="space-between" alignItems="flex-start">
            <Typography fontSize="18px" fontWeight="bold">You run out of free credits for video generation :(</Typography>
            <Button variant='outlined' color='inherit' onClick={() => setPopupEl(null)}>Hide</Button>
          </Box>
          <Typography mb={2}>
            To continue generate videos on our platform upgrade your account to one of subscriptions.
          </Typography>
          <Button variant='contained' component={Link} to={routes.pricing}>Upgrade now</Button>
        </Box>
      </Popover>
    </Box>
  ) : null;
};
