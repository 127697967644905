import { Typography } from "@mui/material";

export const EmotionsHelper = () => (
    <Typography
        component="pre"
        sx={{
        whiteSpace: 'break-spaces',
        wordSpacing: 'normal',
        wordBreak: 'normal',
        wordWrap: 'normal'
        }}
    >
Use tags <strong><code>&lt;emotion name=&quot;emotion_name&quot;&gt; text &lt;/emotion&gt;</code></strong> to add emotions to the generated video.<br />
<br />
All avatars support four emotions:<br />
- neutral<br />
- semismile<br />
- smile<br />
- happiness<br />
- sadness<br />
<br />
Example of multi emotional text:<br />
<strong><code>Neutral text &lt;emotion name=&quot;smile&quot;&gt; text to pronounce with smile &lt;/emotion&gt; &lt;emotion name=&quot;happiness&quot;&gt; text to pronounce with happiness &lt;/emotion&gt;</code></strong>
    </Typography>
)
