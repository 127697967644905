import { Button, ButtonProps, styled } from '@mui/material';
import React, { useRef } from 'react';
import UploadIcon from '@mui/icons-material/Upload';

type UploadButtonProps = Omit<ButtonProps, 'onChange'> & {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  accept?: string;
};

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  position: relative;
  padding: ${({ theme }) => theme.spacing(2)};
  border: 2px dashed ${({ theme }) => theme.palette.primary.main};

  :disabled {
    border: 2px dashed ${({ theme }) => theme.palette.grey[200]};
  }
`;

const StyledInput = styled('input')`
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const UploadButton: React.FC<UploadButtonProps> = ({
  accept = 'audio/x-mp3,audio/mpeg,audio/x-wav',
  children,
  onChange,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <StyledButton startIcon={<UploadIcon />} {...props}>
      {children}
      <StyledInput
        accept={accept}
        type="file"
        ref={inputRef}
        onChange={onChange}
      />
    </StyledButton>
  );
};
