import React from 'react';
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { Google } from '@mui/icons-material';
import { IconButton } from "@mui/material";
import { logger } from '../../../utils/logger';

type GoogleAuthButtonProps = {
    onError: (err: string) => void;
    onSuccess: (response: Omit<TokenResponse, "error" | "error_description" | "error_uri">) => void;
}

export const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
    onError,
    onSuccess
}) => {
    const login = useGoogleLogin({
        onSuccess,
        onError: (codeResponse) => {
            logger('error', codeResponse);
            onError(codeResponse.error_description || 'Cannot login using Google')
        },
        flow: 'implicit',
    });
    return (
        <IconButton
            aria-label="google login"
            onClick={() => {
                login();
            }}
        >
            <Google fontSize="large" />
        </IconButton>
    );
};