export const routes = {
  root: '/',
  login: '/login',
  pricing: '/pricing',
  settings: '/settings',
  registration: '/registration',
  verifyEmail: '/registration/verify-email/:key',
  editor: '/user/editor',
  mobileEditor: '/user/mobile-editor',
  gallery: '/user/gallery',
  publicVideo: '/public-video/:id',
  resetPasswordByEmail: '/password-reset/email',
  resetPassword: '/password-reset/confirm/:uid/:token'
} as const;
