import { useMutation, useQuery } from 'react-query';
import { routes } from '../consts/routes';
import { AuthApi, CaptchaPasswordResetRequest, JWT, JWTEphemeralToken, JWTTokenTOTPRequest, LoginRequest, OAuthData, PasswordResetConfirmRequest, SocialLoginRequest } from '../openapi';
import { redirect } from '../utils/redirect';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  BadRequestError,
  getConfiguration,
  REFRESH_TOKEN_STORAGE_KEY,
  saveToken,
} from './configuration';

export const authApi = new AuthApi(getConfiguration());

const applyAuthToken = (data: JWT) => {
  saveToken(ACCESS_TOKEN_STORAGE_KEY, data.accessToken);
  saveToken(REFRESH_TOKEN_STORAGE_KEY, data.refreshToken);

  redirect(routes.editor);
}

export const useLogin = (onEphemeralToken: (data: JWTEphemeralToken) => void) =>
  useMutation(
    (loginRequest: LoginRequest) => authApi.authLoginCreate({ loginRequest }),
    {
      onSuccess(data) {
        if ((data as JWTEphemeralToken).ephemeralToken) {
          onEphemeralToken(data as JWTEphemeralToken);
        } else {
          applyAuthToken(data as JWT)
        }
      },
    }
  );

export enum SocialNetwork {
  Facebook = 'facebook',
  Apple = 'apple',
  Google = 'google'
}
export const useSocialLogin = (
  onEphemeralToken: (data: JWTEphemeralToken) => void,
  onError: (err: string) => void
) =>
  useMutation(
    ({
      network, socialLoginRequest
    }: {
      network: SocialNetwork,
      socialLoginRequest: SocialLoginRequest
    }) => {
      if (network === 'facebook') {
        return authApi.authFacebookCreate({ socialLoginRequest })
      }
      if (network === 'apple') {
        return authApi.authAppleCreate({ socialLoginRequest })
      }

      return authApi.authGoogleCreate( { socialLoginRequest });
    },
    {
      onError: (error) => {
        if (error instanceof BadRequestError) {
          onError(error.message);
        } else {
          onError('Something went wrong');
        }
      },
      onSuccess(data) {
        if ((data as JWTEphemeralToken).ephemeralToken) {
          onEphemeralToken(data as JWTEphemeralToken);
        } else {
          applyAuthToken(data as JWT)
        }
      },
    }
  );

export const useMfaLogin = () => useMutation(
  (jWTTokenTOTPRequest: JWTTokenTOTPRequest)=> authApi.authLoginCodeCreate({ jWTTokenTOTPRequest }),
  {
    onSuccess: (data) => {
      applyAuthToken(data)
    }
  }
);

const AUTH_OATH_CONFIG = 'oathConfig';

export const useOAuthConfig = () =>
  useQuery<OAuthData>(AUTH_OATH_CONFIG, ({ signal }) =>
    authApi.authOauthDataRetrieve({ signal }),
  );

export const useResetPassword = () => useMutation((captchaPasswordResetRequest: CaptchaPasswordResetRequest ) => authApi.authPasswordResetCreate({ captchaPasswordResetRequest }));

export const useResetPasswordConfirm = () => useMutation((passwordResetConfirmRequest: PasswordResetConfirmRequest) => authApi.authPasswordResetConfirmCreate({ passwordResetConfirmRequest }))