import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './components/Root/Root';
import './fonts/Raleway/Raleway-Thin.ttf';
import './index.css';
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://8853373ff02a45f187cfede0799549d4@o1265387.ingest.sentry.io/4504923522465792",
  release: "spiritme-frontend@1.0.0",
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
);
