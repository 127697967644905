import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getConfiguration } from './configuration';
import { FilesApi, FileObj, PaginatedFileObjList, TypeEnum } from '../openapi';

export type { FileObj };

const FILE_LIBRARY_API_KEY = 'files';
const AUDIO_FILE = 'audio-file';

const fileApi = new FilesApi(getConfiguration(true));

export const useAudioFile = (id?: number) =>
  useQuery(
    [AUDIO_FILE, id],
    ({ signal }) => fileApi.filesRetrieve({ id: id! }, { signal }),
    {
      enabled: !!id,
    },
  );

export const useCreateAudio = (
  onSuccess?: (
    data?: FileObj,
    variables?: { id: number },
  ) => Promise<unknown> | void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (file: File) =>
      fileApi.filesCreate({
        name: file.name,
        file,
      }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData([AUDIO_FILE, { id: data.id }], data);
        onSuccess?.(data);
      },
    },
  );
};

export const useMediaFiles = () =>
  useQuery<PaginatedFileObjList, unknown, FileObj[]>(
    FILE_LIBRARY_API_KEY,
    ({ signal }) => fileApi.filesList({ type: [TypeEnum.Image, TypeEnum.Video] }, { signal }),
    {
      select: (data) => data.results || [],
    },
  );

export const useAddMediaFile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (file: File) =>
      fileApi.filesCreate({
        name: file.name,
        file,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([FILE_LIBRARY_API_KEY]);
      },
    },
  );
};
