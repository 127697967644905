import { Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { checkNotificationSupport } from "../../../utils/checkNotificationsSupport";

type GenerateBlockProps = {
    isLoading: boolean;
    onGenerate: () => void;
    onNotifyToggle: (value: boolean) => void;
    isNotifyEnabled: boolean;
}

export const GenerateBlock: React.FC<GenerateBlockProps> = ({
    isLoading,
    onGenerate,
    isNotifyEnabled,
    onNotifyToggle
}) => {
    const [canNotify, setCanNotify] = useState(false);

    useEffect(() => {
        if (checkNotificationSupport()) {
            setCanNotify(true);
        }
    }, []);

    return (
        <Stack spacing={2} my={2} alignItems="flex-start">
            {canNotify && <FormControlLabel
                control={
                    <Checkbox
                        onChange={(e) => onNotifyToggle(e.target.checked)}
                        value={isNotifyEnabled}
                    />
                }
                label="Notify me"
            />}
            <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={onGenerate}
                disabled={isLoading}
            >
                Generate
            </Button>
        </Stack>
    )
}