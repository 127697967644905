import { Box, Button, Popover, Stack, TextField, Typography } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Voices } from './Voices';
import { TtsPlayer } from './TtsPlayer';
import { EmotionsHelper } from './EmotionsHelper';
import { useVoices } from '../../../apiHooks/useTts';
import React, { useMemo, useState } from 'react';
import { AudioSourceRequest, TaskStatusEnum } from '../../../openapi';
import { useAuthUser } from '../../../apiHooks/useAuth';
import { useEmotionsMarkup } from '../../../apiHooks/useEmotionsMarkup';

type TextAreaProps = {
  audioSource: AudioSourceRequest;
  onChange: (audioSource: AudioSourceRequest) => void;
}

const removeMarkup = (text: string) => text.replace(/<[/]?emotion[\w\s="/]*>/g, '')

export const TextArea: React.FC<TextAreaProps> = ({
    onChange,
    audioSource
}) => {
    const [emotionsEl, setEmotionsEl] = useState<HTMLButtonElement | null>(null);
    const { data: authUser } = useAuthUser();

    const isEmotionsOpen = Boolean(emotionsEl);
    
    const {
        mutate: createMarkup,
        isLoading,
        status,
        data: markupData,
        reset,
        error
    } = useEmotionsMarkup((data) => {
      onChange({
          ...audioSource,
          text: data?.text
      })
    });

    const isInProgress = isLoading || 
      !!status &&
      status !== TaskStatusEnum.Error && 
      status !== TaskStatusEnum.Success
    

    const { data: voices } = useVoices((data) =>
        onChange({ voice: { id: data[0].id } }),
    );
    
    const withMarkup = useMemo(
        () => !!audioSource.text && markupData?.text === audioSource.text,
        [markupData?.text, audioSource.text]
    );

    const handleRemoveMarkup = () => {
        reset();
        onChange({
            ...audioSource,
            text: removeMarkup(audioSource.text || '')
        });
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Voices
            items={voices}
            onChange={(id) => {
              onChange({
                ...audioSource,
                voice: {
                  id,
                },
              })
            }}
            value={audioSource?.voice?.id}
          />
          <TextField
            disabled={isInProgress}
            fullWidth
            id="editor-textarea"
            multiline
            minRows={3}
            inputProps={{ maxLength: authUser?.limits?.slideLength ?? 3000 }}
            helperText={`${audioSource?.text?.length ?? 0} / ${
              authUser?.limits?.slideLength ?? 3000
            }`}
            label="Speech text"
            variant="outlined"
            value={audioSource.text || ''}
            onChange={(e) => {
              reset();
              onChange({
                ...audioSource,
                text: e.target.value,
              })
            }}
          />
          {error && (
            <Typography color="error">{error.message}</Typography>
          )}
          {status === TaskStatusEnum.Error && (
            <Typography color="error">An error occurred during the emotions markup, please try again later</Typography>
          )}
          <Box
            display="flex"
            width="100%"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {audioSource?.voice?.id && (
              <TtsPlayer
                voiceId={audioSource.voice.id}
                text={audioSource.text || ''}
              />
            )}
            <Popover
              open={isEmotionsOpen}
              anchorEl={emotionsEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={() => setEmotionsEl(null)}
            >
              <Box p={2} maxWidth="100%" width="400px">
                <Box mb={1} display="flex" justifyContent="space-between">
                  <Typography fontSize="18px" fontWeight="bold">Emotions markup</Typography>
                  <Button variant='outlined' color='inherit' onClick={() => setEmotionsEl(null)}>Hide</Button>
                </Box>
                <EmotionsHelper />
              </Box>
            </Popover>
            <Stack>
              {withMarkup ? (
                <Button onClick={handleRemoveMarkup}>Remove markup</Button>
              ) : (
                <Button disabled={isInProgress} onClick={() => createMarkup({ text: audioSource.text || '' })}>🪄 Markup emotions</Button>
              )}
              <Button
                onClick={e => setEmotionsEl(e.currentTarget)}
                color="secondary"
                startIcon={<QuestionMarkIcon />}
              >
                How to add emotions
              </Button>
            </Stack>
          </Box>
        </Box>
    )
}