import { Box, CircularProgress, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useVerifyEmail } from '../../../apiHooks/useRegistration';
import { routes } from '../../../consts/routes';

export const VerifyEmail: React.FC = () => {
  const { key = '' } = useParams();
  const { isError, isLoading, isSuccess } = useVerifyEmail(key);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    const timer = setTimeout(() => {
      const nextParam = new URL(window.location.search).searchParams.get('next');
      window.location.assign(`${routes.login}?platform=app${nextParam ? `&next=${nextParam}` : ''}`)
    }, 2000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [isSuccess])

  return (
    <Container component="main" maxWidth="xs">
      <Box
        marginTop={8}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {isLoading && (
          <>
            redirecting...
            <br />
            <CircularProgress />
          </>
        )}
        {isError && 'Something went wrong'}
        {isSuccess && 'Email confirmed'}
      </Box>
    </Container>
  );
};
