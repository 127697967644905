import { debounce } from "@mui/material";
import React, { useEffect, useState } from "react";

type ElementRect = {
    width: number;
    height: number;
    left: number;
    right: number;
    top: number;
    bottom: number;
}

type Result = {
    screenWidth: number;
    screenHeight: number;
    rect: ElementRect | null;
}

export function useResize(element?: React.RefObject<HTMLElement>) {
    const [
        state,
        setState
    ] = useState<Result>({
        screenWidth: 0,
        screenHeight: 0,
        rect: null
    });

    useEffect(() => {
        const onResize = debounce(() => {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            let rect: ElementRect | null = null;
    
            if (element && element.current) {
                const clientRect = element.current.getBoundingClientRect();
    
                rect = {
                    width: clientRect.width,
                    height: clientRect.height,
                    left: clientRect.left,
                    right: clientRect.right,
                    top: clientRect.top,
                    bottom: clientRect.bottom
                };
            }
    
            setState({ screenWidth, screenHeight, rect });
        }, 150);

        window.addEventListener("resize", onResize, false);
        onResize();
        return () => {
            onResize.clear();
            window.removeEventListener("resize", onResize, false);
        };
    }, [element]);

    return state;
}