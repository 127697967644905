import React from 'react';
import { Button } from '@mui/material';
import { Layout } from '../../Layout/Layout';
import { Link } from 'react-router-dom';
import { routes } from '../../../consts/routes';
import { GalleryList } from './GalleryList';

export const Gallery: React.FC = () => (
  <Layout>
    <Button size='large' sx={{ mb: 2 }} variant='outlined' component={Link} to={routes.editor}>
      Create new video
    </Button>
    <GalleryList />
  </Layout>
);
