import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
} from '@mui/material';
import React from 'react';
import { LanguageEnum, TTSVoice } from '../../../openapi';

type VoicesProps = {
  items?: TTSVoice[];
  onChange: (id: number) => void;
  value?: number;
};

export const Voices: React.FC<VoicesProps> = ({ items, onChange, value }) => {
  if (!items) {
    return (
      <Stack direction="row" spacing={2} mb={3}>
        <Skeleton variant="rounded" width={300} height={56} />
        <Skeleton variant="rounded" width={300} height={56} />
      </Stack>
    );
  }
  const voice = items.find((v) => v.id === value);
  const languages = Array.from(new Set(items.map((i) => i.language!)).values());
  const lang = voice?.language ?? languages[0] ?? 'en';
  const availableVoices = items.filter((i) => i.language === lang);
  const voicesMap = items.reduceRight((acc, v) => v.language ? {
    ...acc,
    [v.language]: v.id
  } : acc, {} as Record<LanguageEnum, number>);

  return (
    <Stack direction={{ xs: 'column', sm: 'row'}} maxWidth="100%" spacing={2} mb={3}>
      <FormControl>
        <InputLabel id="lang-select-label">Language</InputLabel>
        <Select
          labelId="lang-select-label"
          label="Language"
          value={lang}
          sx={{ width: 300, maxWidth: '100%' }}
          onChange={(e) => {
            onChange(voicesMap[e.target.value as LanguageEnum]);
          }}
        >
          {languages.map((i) => (
            <MenuItem value={i} key={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="voice-select-label">Voice</InputLabel>
        <Select
          labelId="voice-select-label"
          label="Voice"
          value={value || ''}
          sx={{ width: 300, maxWidth: '100%' }}
          onChange={(e) => onChange(Number(e.target.value))}
        >
          {availableVoices.map((i) => (
            <MenuItem value={i.id} key={i.id}>
              {i.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
