import { useQuery } from 'react-query';
import { AcquiringApi } from '../openapi';
import { getConfiguration } from './configuration';

const PRICING_KEY = 'pricing';

const api = new AcquiringApi(getConfiguration(true));

export const stripePublishableKey =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';

export const usePricing = () =>
  useQuery([PRICING_KEY], ({ signal }) =>
    Promise.all([
      api.acquiringStripePricingRetrieve({ signal }),
      api.acquiringStripeCustomerPortalCreate({ signal }),
    ]),
  );
