import {
    IconButton,
    Skeleton,
    Stack,
} from '@mui/material';
import { Facebook, Apple } from '@mui/icons-material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import AppleSignin from 'react-apple-signin-auth';
import { GoogleAuthButton } from './GoogleAuthButton';
import { logger } from '../../../utils/logger';
import { SocialNetwork, useOAuthConfig } from '../../../apiHooks/useToken';
import React from 'react';

interface AppleSignInResponse {
    authorization: {
        code: string; //
        id_token: string;
        state: string; // "state"
    };
    user: {
        name: {
            firstName: string;
            lastName: string;
        };
        email: string;
    };
}

type SocialLoginButtonsProps = {
    onLogin: (data: {
        network: SocialNetwork;
        socialLoginRequest: { accessToken?: string; idToken?: string; code?: string; }
    }) => void;
    onError: (err: string) => void;
}

export const SocialLoginButtons: React.FC<SocialLoginButtonsProps> = ({ onLogin, onError }) => {
    const { data: oauthConfig } = useOAuthConfig();

    return oauthConfig ? (
        <Stack direction="row">
            {oauthConfig.google && (
                <GoogleOAuthProvider clientId={oauthConfig.google}>
                    <GoogleAuthButton
                        onSuccess={response => onLogin({
                            network: SocialNetwork.Google,
                            socialLoginRequest: {
                                accessToken: response.access_token
                            }
                        })}
                        onError={onError}
                    />
                </GoogleOAuthProvider>
            )}
            {oauthConfig.facebook && (
                <FacebookLogin
                    appId={oauthConfig.facebook}
                    onSuccess={(response) => {
                        onLogin({
                            network: SocialNetwork.Facebook,
                            socialLoginRequest: {
                                accessToken: response!.accessToken,
                            },
                        });
                    }}
                    onFail={(err) => {
                        logger('error', { msg: 'Login Failed!', err });
                        onError('Cannot login using Facebook')
                    }}
                    onProfileSuccess={(resp) => {
                        logger('info', { msg: 'Get Profile Success!', resp });
                    }}
                    render={({ onClick }) => (
                        <IconButton aria-label="facebook login" onClick={onClick}>
                            <Facebook fontSize="large" />
                        </IconButton>
                    )}
                />
            )}
            {oauthConfig.apple && (
                <AppleSignin
                    authOptions={{
                        clientId: oauthConfig.apple,
                        scope: 'email name',
                        redirectURI: window.location.href,
                        state: 'state',
                        nonce: 'nonce',
                        usePopup: true,
                    }}
                    noDefaultStyle
                    onSuccess={({ authorization }: AppleSignInResponse) =>
                        onLogin({
                            network: SocialNetwork.Apple,
                            socialLoginRequest: {
                                idToken: authorization.id_token,
                                code: authorization.code,
                            },
                        })
                    }
                    onError={(err: any) => {
                        logger('error', err);
                        onError('Cannot login using Apple')
                    }}
                    skipScript={false}
                    render={({ onClick }: any) => (
                        <IconButton aria-label="apple login" onClick={onClick}>
                            <Apple fontSize="large" />
                        </IconButton>
                    )}
                />
            )}
        </Stack>
    ) : (
        <Stack direction="row" gap={2} p={1}>
            <Skeleton variant="circular" width={35} height={35} />
            <Skeleton variant="circular" width={35} height={35} />
            <Skeleton variant="circular" width={35} height={35} />
        </Stack>
    )
}