import { useMutation, useQuery } from 'react-query';
import { BadRequestError, getConfiguration } from './configuration';
import { EmotionsMarkup, EmotionsMarkupApi, EmotionsMarkupRequest, TaskStatusEnum } from '../openapi';
import { useCallback, useEffect, useState } from 'react';

const EMOTIONS_MARKUP_API_KEY = 'avatars';

const api = new EmotionsMarkupApi(getConfiguration(true));

export const useEmotionsMarkup = (onSuccess: (data: EmotionsMarkup) => void) => {
    const [isReady, setIsReady] = useState(false);
    const {
        mutate,
        reset: resetData,
        data: { id = 0 } = {},
        ...mutation
    } = useMutation((emotionsMarkupRequest: EmotionsMarkupRequest) => api.emotionsMarkupCreate({ emotionsMarkupRequest }))

    const { data, error, isLoading } = useQuery(
        [EMOTIONS_MARKUP_API_KEY, id],
        () => api.emotionsMarkupRetrieve({ id }),
        {
            enabled: !!id && !isReady,
            refetchInterval: isReady ? undefined : 2000,
            onSuccess: (result) => {
                if (result.status === TaskStatusEnum.Success) {
                    onSuccess(result);
                }
                return result;
            }
        }
    );

    const reset = useCallback(() => {
        resetData();
        setIsReady(false);
    }, [resetData])

    useEffect(() => {
        if (
            data?.status === TaskStatusEnum.Error ||
            data?.status === TaskStatusEnum.Success
        ) {
            setIsReady(true);
        }
    }, [data]);

    return {
        error: (mutation.error || error) as BadRequestError,
        mutate,
        isReady,
        isLoading: mutation.isLoading || isLoading,
        data,
        status: data?.status,
        reset
    }
}
