import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { useAuthUser, useLogout } from '../../apiHooks/useAuth';
import { routes } from '../../consts/routes';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Close } from '@mui/icons-material';
import { ExternalLink } from '../ExternalLink/ExternalLink';

const LogoLink = styled(Link)`
  text-decoration: none;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const StyledAlert = styled(Alert)`
  > .MuiAlert-message {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const getTime = (minutes?: number) =>
  minutes
    ? `${Math.trunc(minutes)} min ${Math.trunc((minutes % 1) * 60)} sec`
    : '';

const NavLinks = () => {
  const { logout } = useLogout();

  return (
    <>
      <MenuItem component={Link} to={routes.settings}>Settings</MenuItem>
      <MenuItem component={Link} to={routes.pricing}>Pricing</MenuItem>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </>
  )
}

type LayoutProps = {
  withAppBar?: boolean;
  maxWidth?: boolean;
}

export const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({ children, maxWidth = true, withAppBar = true }) => {
  const { data } = useAuthUser();
  const [menuEl, setMenuEl] = React.useState<HTMLLIElement | null>(null);
  const menuOpen = Boolean(menuEl);
  const handleMenuClick: React.MouseEventHandler<HTMLLIElement> = (event) => {
    setMenuEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuEl(null);
  };
  // REFACTOR TO CONTEXT
  const [hideStartFreeTrial, setHideStartFreeTrial] = useLocalStorage(
    'hideStartFreeTrial',
    false,
  );

  return (
    <>
      <Box mb={3}>
        {withAppBar && (
          <AppBar position="static">
            <Toolbar>
              <Box display="flex" alignItems="center" flexGrow={1}>
                <LogoLink to={routes.editor}>
                  <Typography
                    noWrap
                    mr={2}
                    fontFamily="monospace"
                    fontWeight={600}
                    color="white"
                  >
                    SpiritMe
                  </Typography>
                </LogoLink>
                <Typography mr={3} ml="auto" fontSize="14px">
                  <Link style={{ color: 'inherit', textDecoration: 'none'}} to={routes.pricing}>
                    {data?.limits.creditsLeft === 0 ? "You have 0 minutes available" : `Video length quota: ${getTime(data?.limits.creditsLeft)}`}
                  </Link>
                </Typography>
                <Menu
                  open={menuOpen}
                  anchorEl={menuEl}
                  onClose={handleMenuClose}
                >
                  <NavLinks />
                </Menu>
                <Box display={{ md: 'none'}}>
                  <MenuItem onClick={handleMenuClick}>Menu</MenuItem>
                </Box>
                <Box display={{ xs: 'none', md: 'flex'}}>
                  <NavLinks />
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        )}
        {data?.subscription?.isDefault && withAppBar && (
          <StyledAlert
            color="info"
            icon={false}
          >
            <Typography fontSize={18} textAlign="center" mr={1}>
              You are on a free trial. To remove watermark and receive video credits activate one of our subscriptions.
            </Typography>
            <Button
              component={Link}
              to={routes.pricing}
              variant="outlined"
              color="primary"
            >
              See pricing
            </Button>
          </StyledAlert>
        )}
        {data?.trailSubscriptionUrl && !hideStartFreeTrial && (
          <Alert
            color="info"
            icon={false}
            action={
              <Stack direction="row" gap={1}>
                <Button
                  component={ExternalLink}
                  href={data?.trailSubscriptionUrl}
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  Start free trial
                </Button>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    setHideStartFreeTrial(true);
                  }}
                >
                  <Close />
                </IconButton>
              </Stack>
            }
          >
            Try our service for free
          </Alert>
        )}
      </Box>
      <Container component="main" maxWidth={maxWidth && "lg"}>
        {children}
      </Container>
    </>
  );
};
