import { Button, CircularProgress, Link, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useOtpActivate, useOtpConfirm } from "../../../apiHooks/useAuth";
import { OtpInput } from '../../OtpInput/OtpInput';
import { QRBlock } from '../../QRBlock/QRBlock';


const APP_STORE =
	"https://apps.apple.com/us/app/google-authenticator/id388497605";
const GOOGLE_PLAY =
	"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pli=1";

type ActivationBlockProps = {
    onConfirm: () => void;
}

export const ActivationBlock: React.FC<ActivationBlockProps> = ({ onConfirm }) => {
    const { isLoading: isQrLoading, data: optData, mutate: activateMfa } = useOtpActivate();
    const { isError, isSuccess, isLoading: isConfirmLoading, mutate: confirmOtp } = useOtpConfirm();
    const [otp, setOtp] = useState('');
  
    useEffect(() => {
      if (otp.length === 6) {
        confirmOtp(otp);
      }
    }, [otp, confirmOtp]);
  
    useEffect(() => {
        if (isSuccess) {
            onConfirm();
        }
    }, [isSuccess, onConfirm]);
  
    return (
      <>
        <Typography variant='h4'>Protect your account with 2-Step Verification</Typography>
        <Typography>Set up an extra layer of protection to prevent hackers from gaining access to your account. If you use 2-step authentication to sign in, it helps keep your personal information secure and private.</Typography>
        <Typography>Download Google Authenticator from{" "}
        <Link href={GOOGLE_PLAY} target="_blank" rel="noopener">
          Google Play
        </Link>{" "}
        or{" "}
        <Link href={APP_STORE} target="_blank" rel="noopener">
          App Store
        </Link></Typography>
        {optData?.provisioningUri ? (
          <>
            <Typography mt={4} mb={1}>Please use Google Authenticator to scan this QR code. If you lost access to your one time password you won&apos;t be able to access website. Please keep it safe.</Typography>
            <QRBlock
                data={optData?.provisioningUri}
                width="182px"
                height="182px"
              />
            <Typography mt={2} mb={1}>Enter code from Google Authenticator to confirm activation</Typography>
            <OtpInput
              hasErrored={isError}
              isDisabled={isConfirmLoading}
              numInputs={6}
              onChange={setOtp}
              value={otp}
            />
            {isConfirmLoading && <CircularProgress />}
          </>
        ) : (
          <>
            <Button color="primary" disabled={isQrLoading} onClick={() => activateMfa()}>Enable 2FA</Button>
            <br />
            {isQrLoading && <CircularProgress />}
          </>
        )}
      </>
    );
  }
  