import React from 'react';
import { DraggableTabs } from './DraggableTabs';
import { SlideForm } from './EditorContext/reducer';

interface SlidePickerProps {
  moveSlide: (dragIndex: React.Key, hoverIndex: React.Key) => void;
  selected: string;
  slides: SlideForm[];
  createSlide: () => void;
  deleteSlide: (slideIndex: string, newSelected: string) => void;
  selectSlide: (selected: string) => void;
}

export const SlidePicker: React.FC<SlidePickerProps> = ({
  slides,
  moveSlide,
  selected,
  createSlide,
  selectSlide,
  deleteSlide,
}) => {
  const onEdit = (targetKey: any, action: 'add' | 'remove') => {
    if (action === 'add') {
      createSlide();
    } else {
      const oldIndex = slides.findIndex((s) => s.id === selected);
      const filterIds = slides.map((s) => s.id).filter((id) => id !== selected);
      const newSelectedSlideId =
        selected !== targetKey
          ? selected
          : filterIds[oldIndex > 0 ? oldIndex - 1 : 0];
      deleteSlide(targetKey, newSelectedSlideId);
    }
  };

  return (
    <DraggableTabs
      moveSlide={moveSlide}
      type="editable-card"
      items={[
        ...slides.map((s, i, array) => ({
          label: `Slide ${i + 1}`,
          key: s.id,
          closable: array.length !== 1,
        })),
      ]}
      activeKey={selected.toString()}
      onEdit={onEdit}
      onChange={(key) => selectSlide(key)}
    />
  );
};
