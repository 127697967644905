import { CircularProgress, Link, Typography } from '@mui/material';
import React from 'react';
import { useAuthUser } from '../../../apiHooks/useAuth';
import { Layout } from '../../Layout/Layout';
import { ActivationBlock } from './ActivationBlock';
import { DeactivationBlock } from './DeactivationBlock';

export const MFASettings: React.FC = () => {
  const { data: user, refetch, isFetching } = useAuthUser();

  if (!user) {
    return null;
  }
  
  if (isFetching) {
    return <CircularProgress />;
  }

  return (
    <Layout>
      {user.isTotpEnabled ? <DeactivationBlock onDeactivate={refetch} /> : <ActivationBlock onConfirm={refetch} />}
      <Typography mt={4}>
        iOS app: <Link href="https://apps.apple.com/en/app/spiritme/id1615635565">https://apps.apple.com/en/app/spiritme/id1615635565</Link><br />
        Contact us: <Link href="mailto:team@spiritme.tech">team@spiritme.tech</Link><br />
        Support and updates: <Link href="https://discord.gg/gK2KrU9nP6">https://discord.gg/gK2KrU9nP6</Link>
      </Typography>
    </Layout>
  );
}
