import { useQuery } from 'react-query';
import { Avatar, AvatarsApi, AvatarsListStatusEnum } from '../openapi';
import { getConfiguration } from './configuration';

const AVATARS_API_KEY = 'avatars';

const api = new AvatarsApi(getConfiguration(true));

export const useAvatars = (onSuccess?: (data: Avatar[]) => void) =>
  useQuery(
    AVATARS_API_KEY,
    ({ signal }) =>
      api.avatarsList({ status: [AvatarsListStatusEnum.Ready] }, { signal }),
    {
      select: (data) => data.results || [],
      onSuccess,
    },
  );
