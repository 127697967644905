import { Box, Stack, Typography, TextField } from "@mui/material"
import React, { useContext, useMemo } from "react";
import { useAvatars } from "../../../apiHooks/useAvatars";
import { EditorContext } from "./EditorContext/EditorContext";
import { SlideForm } from "./EditorContext/reducer";
import { ResolutionEditor } from "./ResolutionEditor";
import { SlideEditor } from "./SlideEditor";
import { GenerateBlock } from "./GenerateBlock";

type EditorSettingsProps = {
    onGenerate: () => void;
    onNotifyToggle: (v: boolean) => void;
    isNotifyEnabled: boolean;
    isGenerating: boolean;
}

export const EditorSettings: React.FC<EditorSettingsProps> = ({
    onGenerate,
    onNotifyToggle,
    isNotifyEnabled,
    isGenerating
}) => {
    const {
        state: { requestData, currentSlide },
        setName,
        setResolution,
        updateBackground,
        updateAudio,
        updateLayer,
    } = useContext(EditorContext);

    const { data: avatars } = useAvatars();

    const currentSlideData = useMemo(
        () => requestData.slides.find((s: SlideForm) => s.id === currentSlide),
        [requestData.slides, currentSlide],
    );

    const avatar = useMemo(() => avatars?.find(
        (a) => {
          const slide = requestData?.slides?.find((s: SlideForm) => s.id === currentSlide);
    
          return slide?.layers?.[0]?.avatar?.id === a.id;
        }
      ), [requestData?.slides, avatars, currentSlide]);
    
    return (
        <Box>
            <Stack spacing={2}>
                <Typography variant="h5" mb={2}>
                    Common settings
                </Typography>
                <TextField
                    id="name"
                    label="Name"
                    variant="outlined"
                    value={requestData.name || ''}
                    onChange={(e) =>
                        setName(e.target.value)
                    }
                />
            </Stack>
            <Box mt={2} mb={2}>
                <ResolutionEditor
                    resolution={requestData.resolution}
                    onChange={setResolution}
                    fitAvatar={() => {
                        setResolution({
                            width: avatar?.frameWidth ?? 1920,
                            height: avatar?.frameHeight ?? 1080,
                        });
                    }}
                />
            </Box>
            <Typography variant="h5" mb={2}>
                Slide settings
            </Typography>
            <Stack spacing={2}>
                {currentSlideData && (
                    <SlideEditor
                        noScene
                        avatars={avatars}
                        slide={currentSlideData}
                        resolution={requestData.resolution}
                        onLayerChange={updateLayer}
                        onBackgroundChange={updateBackground}
                        onAudioChange={updateAudio}
                    />
                )}
            </Stack>
            <GenerateBlock 
                isLoading={isGenerating}
                onGenerate={onGenerate}
                isNotifyEnabled={isNotifyEnabled}
                onNotifyToggle={onNotifyToggle}
            />
        </Box>
    )
}