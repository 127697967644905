import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthUser, useTokenVerify } from '../../apiHooks/useAuth';
import { routes } from '../../consts/routes';
import { setYaMetricaUuid } from '../../utils/yaMetrica';

export const AuthGuard: React.FC = () => {
  const { isLoading, isSuccess, isError } = useTokenVerify();
  const { data } = useAuthUser(isSuccess);

  useEffect(() => {
    if (data?.uuid) {
      setYaMetricaUuid(data?.uuid);
    }
  }, [data?.uuid]);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <Navigate to={routes.login} />;
  }

  return <Outlet />;
};
