import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import React from 'react';

export const Link: React.FC<RouterLinkProps & MuiLinkProps> = (props) => (
  <MuiLink {...props} component={RouterLink} />
);
