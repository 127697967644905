import React, { useRef } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@mui/material';
import { FileObj, useAddMediaFile } from '../../apiHooks/useFiles';
import { styled } from '../../utils/styled';
import { AddCircle, HideImage } from '@mui/icons-material';

const StyledCard = styled(Card)<{ $active: boolean }>`
  flex-shrink: 0;
  max-width: 180px;

  ${({ $active, theme }) =>
    $active &&
    `
    border-color: ${theme.palette.primary.main};
  `}
`;

interface ChooseBackgroundProps {
  activeId?: number | null;
  items: FileObj[];
  onChange: (m?: number) => void;
  enabled?: boolean;
}

export const ChooseBackground: React.FC<ChooseBackgroundProps> = ({
  activeId,
  items,
  onChange,
  enabled = true,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { mutate } = useAddMediaFile();
  return (
    <>
      <Typography variant="h6" mb={2}>
        Choose background
      </Typography>
      <Box overflow="auto">
        <Stack direction="row" spacing={2} py={2} overflow="auto">
          <Card style={{ minWidth: 100 }} variant="outlined">
            <CardActionArea
              sx={{ height: '100%' }}
              onClick={() => inputRef.current?.click()}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <AddCircle fontSize="large" />
                <input
                  accept="image/png,image/jpeg,video/mp4,video/quicktime,.mov, .mp4"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={(event) => {
                    const file: File | undefined = event?.target?.files?.[0];
                    if (file) {
                      mutate(file, {
                        onSuccess: () => {
                          if (inputRef.current?.value) {
                            inputRef.current.value = '';
                          }
                        },
                      });
                    }
                  }}
                  ref={inputRef}
                />
              </CardContent>
            </CardActionArea>
          </Card>
          <StyledCard
            $active={!activeId}
            style={{ minWidth: 100 }}
            variant="outlined"
          >
            <CardActionArea
              sx={{ height: '100%' }}
              onClick={() => onChange(undefined)}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <HideImage fontSize="large" />
              </CardContent>
            </CardActionArea>
          </StyledCard>
          {items.map((i) => (
            <StyledCard variant="outlined" $active={i.id === activeId} key={i.id}>
              <CardActionArea onClick={() => onChange(i.id)} disabled={!enabled}>
                <CardMedia
                  component="img"
                  height="140"
                  style={{
                    objectFit: 'contain',
                  }}
                  image={i.thumbnail!}
                  alt={i.name || ''}
                />
              </CardActionArea>
            </StyledCard>
          ))}
        </Stack>
      </Box>
    </>
  );
};
