/* eslint-disable react-hooks/exhaustive-deps */
// import { Theme } from '@mui/material';
// import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Rnd, RndResizeCallback, RndDragCallback } from 'react-rnd';
import { useResize } from '../../../hooks/useResize';
import {
  Avatar,
  ViewTypeEnum,
  VideoRequestResolution,
} from '../../../openapi';
import { styled } from '../../../utils/styled';

export type CharacterParams = {
  /**
   * middle of image
   * from 0 to 1
   */
  x: number;
  /**
   * from 0 to 1
   */
  y: number;
  /**
   * from 0 to 1
   */
  scale: number;
};

const SCENE_HEIGHT = 360;
const SCENE_HEIGHT_MOBILE = 200;
const SCENE_BOUND_ID = 'scene-bound-id';

const transformXToMiddle = (
  x: number,
  scale: number,
  sceneRatio: number,
  characterAspect: number,
) => x + (characterAspect / sceneRatio / 2) * scale;
const transformXToLeft = (
  x: number,
  scale: number,
  sceneRatio: number,
  characterAspect: number,
) => x - (characterAspect / sceneRatio / 2) * scale;

const transformYToTop = (y: number, scale: number) => scale + y;
const transformYToBottom = (y: number, scale: number) => -scale + y;
const getSize = (
  scale: number,
  position: ViewTypeEnum,
  characterAspect: number,
  sceneHeight: number
) => {
  if (position === ViewTypeEnum.Circular) {
    return {
      width: sceneHeight * scale,
      height: sceneHeight * scale,
    };
  }

  return {
    width: sceneHeight * characterAspect * scale,
    height: sceneHeight * scale,
  };
};
const getPosition = (
  resolution: VideoRequestResolution,
  avatarPosition: ViewTypeEnum,
  avatar: Avatar,
  params: CharacterParams,
  sceneHeight: number
) => {
  const sceneRatio = (resolution.width || 0) / (resolution.height || 1);
  const characterRatio =
    avatarPosition === ViewTypeEnum.Circular
      ? 1
      : avatar.frameWidth / avatar.frameHeight;

  return {
    x:
      transformXToLeft(params.x, params.scale, sceneRatio, characterRatio) *
      sceneHeight *
      sceneRatio,
    y: transformYToBottom(params.y, params.scale) * sceneHeight,
  };
};

const StyledBounds = styled('div')<{
  $avatarWidth: number;
  $sceneWidth: number;
}>`
  position: absolute;
  left: ${({ $avatarWidth, $sceneWidth }) =>
    `-${($sceneWidth + $avatarWidth) / 2}px`};
  top: -50%;
  width: ${({ $avatarWidth, $sceneWidth }) =>
    `${($sceneWidth + $avatarWidth / 2) * 2}px`};
  height: 200%;
`;

const BorderBox = styled('div')`
  width: 100%;
  height: ${SCENE_HEIGHT}px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: ${SCENE_HEIGHT_MOBILE}px;
  }
`;

const SceneContainer = styled('div')<{
  $background?: string | null;
  $sceneRatio: number;
  $sceneHeight: number;
}>`
  position: relative;
  ${({$sceneHeight, $sceneRatio}) => `
    height: ${$sceneHeight}px;
    width: ${$sceneHeight * $sceneRatio}px;
  `}
  overflow: hidden;
  background-color: black;
  background-image: url(${({ $background }) => $background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({$sceneHeight, $sceneRatio}) => `
      height: ${$sceneHeight}px;
      width: ${$sceneHeight * $sceneRatio}px;
    `}
  },
`;

const CharacterContainer = styled('div')<{
  $image: string;
  $position: ViewTypeEnum;
}>`
  position: relative;
  border: 1px solid lightblue;

  width: 100%;
  height: 100%;
  background-color: ${({ $position }) =>
    $position === ViewTypeEnum.Circular ? 'white' : 'initial'};
  border-radius: ${({ $position }) =>
    $position === ViewTypeEnum.Circular ? '50%' : 0};
  background-image: url(${({ $image }) => $image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Grip = styled('div')`
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-blend-mode: difference;
  background-color: lightblue;
`;

interface SceneProps {
  onChange: (data: CharacterParams) => void;
  defaultParams: CharacterParams;
  background?: string | null;
  avatar: Avatar;
  avatarPosition: ViewTypeEnum;
  resolution: VideoRequestResolution;
}

export interface SceneRef {
  setParams: (
    params: CharacterParams,
    avatarPosition: ViewTypeEnum,
    avatar: Avatar,
    resolution: VideoRequestResolution,
  ) => void;
}

export const Scene = React.forwardRef<SceneRef, SceneProps>(
  (
    { onChange, defaultParams, background, avatar, avatarPosition, resolution },
    ref,
  ) => {
    const wrapRef = useRef<HTMLDivElement | null>(null);
    const { rect: wrapRect } = useResize(wrapRef);

    // const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    // const sceneHeight = isMobile ? SCENE_HEIGHT_MOBILE : SCENE_HEIGHT;
    
    const sceneRatio = (resolution.width || 0) / (resolution.height || 1);
    const sceneHeight = useMemo(() => {
      if (!wrapRect) {
        return 0;
      }
      const height = wrapRect.width / sceneRatio;

      if (height > wrapRect.height) {
        return wrapRect.height;
      }

      return height;
    }, [wrapRect?.height, wrapRect?.width, sceneRatio]);

    const [scale, setScale] = useState(defaultParams.scale);
    const [position, setPosition] = useState(() =>
      getPosition(resolution, avatarPosition, avatar, defaultParams, sceneHeight),
    );
    const [isDragging, setIsDragging] = useState(false);

    const avatarSize = getSize(
      scale,
      avatarPosition,
      avatar.frameWidth / avatar.frameHeight,
      sceneHeight
    );

    // eslint-disable-next-line no-shadow
    const handleResize: RndResizeCallback = (e, dir, input, delta, pos) => {
      const height = parseFloat(input.style.height);
      setScale(() => height / sceneHeight);

      setPosition({
        x: pos.x,
        y: pos.y,
      });
    };

    const commitChanges = () => {
      const characterRatio =
        avatarPosition === ViewTypeEnum.Circular
          ? 1
          : avatar.frameWidth / avatar.frameHeight;
      onChange({
        scale,
        x: transformXToMiddle(
          position.x / (sceneHeight * sceneRatio),
          scale,
          sceneRatio,
          characterRatio,
        ),
        y: transformYToTop(position.y / sceneHeight, scale),
      });
      setIsDragging(false)
    };

    const handleDrag: RndDragCallback = (e, { x, y }) => {
      setPosition({
        x,
        y,
      });
    };

    useEffect(() => {
      if (isDragging) {
        return;
      }
      setPosition(
        getPosition(resolution, avatarPosition, avatar, defaultParams, sceneHeight),
      );
      setScale(defaultParams.scale);
    }, [
      isDragging,
      resolution.width,
      resolution.height,
      avatar.id,
      avatarPosition,
      defaultParams,
      sceneHeight
    ]);

    useEffect(() => {
      if (typeof ref === 'object' && ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = {
          // eslint-disable-next-line no-shadow
          setParams(params, avPos, av, resolultion) {
            setScale(params.scale);
            setPosition(getPosition(resolultion, avPos, av, params, sceneHeight));
          },
        };
      }
    }, [ref, sceneHeight]);

    return (
      <BorderBox ref={wrapRef}>
        <SceneContainer $sceneHeight={sceneHeight} $sceneRatio={sceneRatio} $background={background}>
          <StyledBounds
            id={SCENE_BOUND_ID}
            $avatarWidth={avatarSize.width * scale}
            $sceneWidth={sceneRatio * sceneHeight}
          />
          <Rnd
            position={{
              x: position.x,
              y: position.y,
            }}
            size={avatarSize}
            lockAspectRatio
            bounds={`#${SCENE_BOUND_ID}`}
            onResize={handleResize}
            onResizeStop={commitChanges}
            onDragStart={() => setIsDragging(true)}
            onDrag={handleDrag}
            onDragStop={commitChanges}
          >
            <CharacterContainer
              $position={avatarPosition}
              $image={avatar.preview || ''}
            >
              <Grip sx={{ left: -4, top: -4 }} />
              <Grip sx={{ right: -4, top: -4 }} />
              <Grip sx={{ left: -4, bottom: -4 }} />
              <Grip sx={{ right: -4, bottom: -4 }} />
            </CharacterContainer>
          </Rnd>
        </SceneContainer>
      </BorderBox>
    );
  },
);
