import React from "react";
import ReactOtpInput, { OtpInputProps } from "./OtpInputBase";
import { styled } from "../../utils/styled";

const InputWrap = styled("div")<{
	$isError?: boolean;
}>`
    margin-top: 2px;
	input {
		font-size: 24px;
		font-weight: 500;
		width: 48px !important;
		height: 56px;
		margin-right: ${({ theme }) => theme.spacing(1)};
		border-radius: 12px;
		border: 1px solid ${({ $isError }) => ($isError ? 'red' : 'gray')};
	}
`;

export const OtpInput: React.FC<OtpInputProps> = ({ ...props }) => (
	<InputWrap $isError={props.hasErrored} data-e2e="otp-wrap">
		<ReactOtpInput shouldAutoFocus isInputNum {...props} />
	</InputWrap>
);
