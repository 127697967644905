import React from 'react';
import { Resolution } from '../../../openapi';
import { Button, Grid, TextField } from '@mui/material';

const DEFAULT_RESOLUTIONS = [
  {
    label: '16:9',
    resolution: {
      width: 1920,
      height: 1080,
    },
  },
  {
    label: '9:16',
    resolution: {
      width: 1080,
      height: 1920,
    },
  },
  {
    label: '4:3',
    resolution: {
      width: 1440,
      height: 1080,
    },
  },
  {
    label: '3:4',
    resolution: {
      width: 1080,
      height: 1440,
    },
  },
  {
    label: '1:1',
    resolution: {
      width: 1440,
      height: 1440,
    },
  },
];

interface ResolutionEditorProps {
  resolution: Resolution;
  onChange: (resolution: Required<Resolution>) => void;
  fitAvatar: () => void;
}

export const ResolutionEditor: React.FC<ResolutionEditorProps> = ({
  resolution,
  onChange,
  fitAvatar,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={3} md={2}>
      <TextField
        id="resolutionWidth"
        type="number"
        label="Width"
        variant="outlined"
        value={resolution.width}
        disabled
        onChange={(e) => {
          onChange?.({
            height: resolution.height!,
            width: (e.target as HTMLInputElement).valueAsNumber,
          });
        }}
      />
    </Grid>
    <Grid item xs={3} md={2}>
      <TextField
        id="resolutionHeight"
        type="number"
        label="Height"
        variant="outlined"
        disabled
        value={resolution.height}
        onChange={(e) => {
          onChange({
            height: (e.target as HTMLInputElement).valueAsNumber,
            width: resolution.width!,
          });
        }}
      />
    </Grid>
    <Grid item xs={12} md={8} display="flex" flexDirection="row" flexWrap="wrap">
      <Button onClick={() => fitAvatar()}>Fit current avatar</Button>
      {DEFAULT_RESOLUTIONS.map((item) => (
        <Button key={item.label} onClick={() => onChange(item.resolution)}>
          {item.label}
        </Button>
      ))}
    </Grid>
  </Grid>
);
