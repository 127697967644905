import React, { useEffect, useRef } from "react";
import { useScript } from "../../hooks/useScript";

const GOOGLE_RECAPTCHA_SITE_KEY = "6LfXMIokAAAAAFjNB36Pn-nD_VDO4cQwYshPaAVA"

type RecaptchaV2Props = {
    action: 'reset' | 'register'
    recaptchaId?: string;
    onLoad?: () => void;
};
export type RecaptchaRef = {
    getToken: () => Promise<string>;
};

export const Recaptcha = React.forwardRef<RecaptchaRef, RecaptchaV2Props>(
    ({ recaptchaId = "recaptcha", onLoad, action }, ref) => {
        const isLoadedRef = useRef(false);
        
        const { isLoaded } = useScript(`https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_SITE_KEY}`);

        useEffect(() => {
            if (isLoaded) {
                isLoadedRef.current = true;
                onLoad?.();
            }
        }, [isLoaded, onLoad]);

        useEffect(() => {
            const getToken = () =>
                new Promise<string>((resolve, reject) => {
                    if (isLoadedRef.current) {
                        const grecapthca = window.grecaptcha;
                        if (!grecapthca) {
                            reject();
                            return;
                        }
                        grecapthca.ready(() => {
                            grecapthca
                                .execute(GOOGLE_RECAPTCHA_SITE_KEY, { action })
                                .then((token) => {
                                    resolve(token);
                                });
                        });
                    } else {
                        setTimeout(async () => {
                            resolve(await getToken());
                        }, 300);
                    }
                });
            if (typeof ref === "object" && ref) {
                /* eslint-disable no-param-reassign */
                ref.current = {
                    getToken,
                };
                /* eslint-enable no-param-reassign */
            }
        }, [ref, action]);

        return (
            <div
                id={recaptchaId}
                className={recaptchaId}
                data-sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                data-size="invisible"
            />
        );
    }
);
