import {
    LayerRequest,
    ViewTypeEnum,
    Resolution,
    SlideRequest,
    VideoRequest,
    Avatar
} from "../../../../openapi";
import { Action, ActionType } from "./actions";

export const enum TtsType {
    Audio = 'Audio',
    Text = 'Text',
}

export type SlideForm = SlideRequest & {
    id: string;
    layers: Array<LayerRequest>;
    sourceType: TtsType;
};

export type EditorContextState = {
    requestData: Required<VideoRequest> & {
        slides: SlideForm[];
        resolution: Required<Resolution>;
    };
    currentSlide: string;
}

function arrayMove<T>(arr: T[], fromIndex: number, toIndex: number): T[] {
    const retArr = [...arr];
    const element = retArr[fromIndex];
  
    retArr.splice(fromIndex, 1);
    retArr.splice(toIndex, 0, element);
    
    return retArr;
}

const createNewSlide = (voiceId?: number, avatarId = 0): SlideForm => {
    const initialAvatarLayer: LayerRequest = {
        avatar: {
            id: avatarId,
        },
        x: 0.5,
        y: 1,
        scale: 1,
        viewType: ViewTypeEnum.Rectangular,
    };

    return {
        id: Date.now().toString(),
        sourceType: TtsType.Text,
        audioSource: {
            text: '',
            voice: voiceId ? {
                id: voiceId
            } : null,
            file: null,
        },
        layers: [initialAvatarLayer]
    }
};

const getInitialRequestData = (avatar?: Avatar, voiceId?: number) => {
    const resolution: Required<Resolution> = !avatar || avatar?.supportsBackgroundRemoval ? {
        width: 1920,
        height: 1080,
    } : {
        width: avatar?.frameWidth,
        height: avatar?.frameHeight,
    };

    return {
        name: 'New video',
        slides: [createNewSlide(voiceId, avatar?.id)],
        resolution,
        autoEmotionsMarkup: false
    };
};

export const defaultState: EditorContextState = (() => {
    const requestData = getInitialRequestData();

    return {
        requestData,
        currentSlide: ""
    }
})()

export const reducer = (prevState: EditorContextState, action: Action): EditorContextState => {
    if (action.type === ActionType.INIT) {
        const requestData = getInitialRequestData(action.payload.avatar, action.payload.voiceId);
        
        return {
            requestData,
            currentSlide: requestData.slides[0].id,
        }
    }
    if (action.type === ActionType.SET_RESOLUTION) {
        return {
            ...prevState,
            requestData: {
                ...prevState.requestData,
                resolution: action.payload
            }
        }
    }
    if (action.type === ActionType.SET_NAME) {
        return {
            ...prevState,
            requestData: {
                ...prevState.requestData,
                name: action.payload
            }
        }
    }
    if (action.type === ActionType.MOVE_SLIDE) {
        const {requestData} = prevState;
        const hoveredSlideIndex = requestData.slides.findIndex(
            (s: SlideForm) => s.id === action.payload.dragIndex,
        );
        const dragSlideIndex = requestData.slides.findIndex(
            (s: SlideForm) => s.id === action.payload.hoverIndex,
        );
        return {
            ...prevState,
            requestData: {
                ...requestData,
                slides: arrayMove(
                    requestData.slides,
                    dragSlideIndex,
                    hoveredSlideIndex,
                ),
            }
        };
    }
    if (action.type === ActionType.SET_SELECTED_SLIDE) {
        return {
            ...prevState,
            currentSlide: action.payload
        }
    }
    if (action.type === ActionType.DELETE_SLIDE) {
        return {
            ...prevState,
            requestData: {
                ...prevState.requestData,
                slides: prevState.requestData.slides.filter((s: SlideForm) => s.id !== action.payload.id),
            },
            currentSlide: action.payload.selectedId
        }
    }
    if (action.type === ActionType.CREATE_SLIDE) {
        const selectedSlide = prevState.requestData.slides.find((s: SlideForm) => s.id === prevState.currentSlide);
        const slide = createNewSlide(selectedSlide?.audioSource.voice?.id, selectedSlide?.layers[0].avatar?.id);

        return {
            ...prevState,
            requestData: {
                ...prevState.requestData,
                slides: [...prevState.requestData.slides, slide]
            },
            currentSlide: slide.id
        }
    }
    if (action.type === ActionType.UPDATE_LAYER) {
        return {
            ...prevState,
            requestData: {
                ...prevState.requestData,
                slides: prevState.requestData.slides.map((s: SlideForm): SlideForm => s.id === action.payload.slideId ? {
                    ...s,
                    layers: [{...s.layers[0], ...action.payload.layer}]
                } : s)
            },
        }
    }
    if (action.type === ActionType.UPDATE_BACKGROUND) {
        return {
            ...prevState,
            requestData: {
                ...prevState.requestData,
                slides: prevState.requestData.slides.map((s: SlideForm): SlideForm => {
                    if (s.id !== action.payload.slideId) {
                        return s;
                    }
                    
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { background, ...slide } = s;

                    if (!action.payload.background) {
                        return slide;
                    }

                    return {
                        ...slide,
                        background: {
                            id: action.payload.background
                        }
                    }
                })
            },
        }
    }
    if (action.type === ActionType.UPDATE_AUDIO) {
        return {
            ...prevState,
            requestData: {
                ...prevState.requestData,
                slides: prevState.requestData.slides.map((s: SlideForm): SlideForm => {
                    const { slideId, ...data } = action.payload;

                    return s.id === slideId ? {
                        ...s,
                        ...data
                    } : s
                })
            },
        }
    }
    if (action.type === ActionType.SET_AUTO_EMOTIONS) {
        return {
            ...prevState,
            requestData: {
                ...prevState.requestData,
                autoEmotionsMarkup: action.payload
            },
        }
    }
    return prevState;
}