import { useQuery } from 'react-query';
import { PublicVideosApi } from '../openapi';
import { getConfiguration } from './configuration';

const PUBLIC_VIDEOS_API_KEY = 'voices';

const api = new PublicVideosApi(getConfiguration());

export const usePublicVideo = (uuid: string) =>
  useQuery([PUBLIC_VIDEOS_API_KEY, uuid], ({ signal }) =>
    api.publicVideosRetrieve({ uuid }, { signal }),
  );
