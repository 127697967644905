import React, { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useAuthUser, useTokenVerify } from "../../apiHooks/useAuth";
import { initIntercom } from "./initIntercom";
import { GTAG_ID } from "../../utils/googleAnalytics";

export const CommonLogicBlock: React.FC = () => {
  const { isSuccess, isError } = useTokenVerify();
  const { data } = useAuthUser(isSuccess);

    useEffect(() => {
        if (data?.uuid || isError) {
            initIntercom(data?.uuid);
        }
    }, [data?.uuid, isError]);

    useEffect(() => {
        if(data?.uuid) {
            window.gtag?.('config', GTAG_ID, {
                user_id: data.uuid
            })
        }
    }, [data?.uuid]);

    return <Outlet />;
}
