import { useMutation, useQuery } from 'react-query';
import { Video, VideosApi, VideoRequest, PublishVideo } from '../openapi';
import { getConfiguration } from './configuration';

const api = new VideosApi(getConfiguration(true));

export const useVideos = (isPolling?: boolean) =>
  useQuery('videos', ({ signal }) => api.videosList({}, { signal }), {
    select: (data) => data.results,
    refetchInterval: isPolling ? 20 * 1000 : undefined,
  });

export const usePublishVideo = (onSuccess: (data: PublishVideo) => void) =>
  useMutation('videos', (id: number) => api.videosPublishCreate({ id }), {
    onSuccess,
  });

export const useCreateVideo = (
  onSuccess?: (data: Video) => void,
  onError?: (err: unknown) => void,
) =>
  useMutation(
    'videos-create',
    async (data: VideoRequest) => api.videosCreate({ videoRequest: data }),
    {
      onSuccess,
      onError,
    },
  );
