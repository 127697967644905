import React, { useMemo } from 'react';
import { Avatar, ViewTypeEnum, Resolution, Layer, LayerAvatar, AudioSourceRequest } from '../../../openapi';
import { Avatars } from './Avatars';
import {
  Box,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { CharacterParams, Scene } from './Scene';
import { ChooseBackground } from '../../ChooseBackground/ChooseBackground';

import { useMediaFiles } from '../../../apiHooks/useFiles';
import { AudioSourcePicker } from './AudioSourcePicker';
import { SlideForm, TtsType } from './EditorContext/reducer';

interface SlideEditorProps {
  noScene?: boolean;
  avatars?: Avatar[];
  slide: SlideForm;
  resolution: Required<Resolution>;
  onLayerChange: (data: { slideId: string; layer: Partial<Layer>}) => void;
  onBackgroundChange: (data: { slideId: string; background: number | undefined; }) => void;
  onAudioChange: (data: Partial<{ sourceType: TtsType; audioSource: AudioSourceRequest }> & { slideId: string }) => void;
}

export const SlideEditor: React.FC<SlideEditorProps> = ({
  avatars,
  slide,
  resolution,
  onLayerChange,
  onBackgroundChange,
  onAudioChange,
  noScene
}) => {
  const { data: mediaFiles } = useMediaFiles();
  const currentLayer = slide.layers?.[0];
  const avatar = useMemo(
    () => avatars?.find((i) => i.id === currentLayer?.avatar?.id),
    [currentLayer, avatars],
  );

  const currentBackground = useMemo(
    () => mediaFiles?.find((i) => i.id === slide.background?.id),
    [slide.background, mediaFiles],
  );

  const handleAvatarPositionChange = (
    e: any,
    viewType: ViewTypeEnum,
  ) => {
    const newParams = {
      scale: viewType === ViewTypeEnum.Circular ? 0.4 : 1,
      x: 0.5,
      y: 1,
    };
    onLayerChange({
      slideId: slide.id,
      layer: {
        ...newParams,
        viewType: viewType || ViewTypeEnum.Rectangular,
      }
    })
  };
  const handleAvatarParamsChange = (layer: CharacterParams) => {
    onLayerChange({
      slideId: slide.id,
      layer
    })
  };

  const handleAvatarChange = (id: number) => {
    onLayerChange({
      slideId: slide.id,
      layer: {
        avatar: {
          id,
        } as LayerAvatar,
      }
    })
  };

  if (!slide.layers[0]) {
    return null;
  }

  return (
    <>
      <Avatars
        items={avatars}
        onChange={handleAvatarChange}
        value={currentLayer.avatar?.id}
      />
      <Box>
        <Typography variant="h6" mb={2}>
          Choose avatar orientation
        </Typography>

        <ToggleButtonGroup
          exclusive
          onChange={handleAvatarPositionChange}
          value={currentLayer?.viewType}
        >
          <ToggleButton value={ViewTypeEnum.Rectangular}>
            Rectangular
          </ToggleButton>
          <ToggleButton value={ViewTypeEnum.Circular}>
            Circular
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {!noScene && <Box my={3} display="flex">
        {avatar && (
          <Scene
            avatarPosition={
              currentLayer?.viewType || ViewTypeEnum.Rectangular
            }
            avatar={avatar}
            resolution={resolution}
            background={currentBackground?.thumbnail}
            onChange={handleAvatarParamsChange}
            defaultParams={slide.layers[0] as CharacterParams}
          />
        )}
        {!avatar && (
          <Skeleton>
            <Box width={{ xs: 320, md: 640}} height={{ xs: 180, md: 360 }} />
          </Skeleton>
        )}
      </Box>}
      <ChooseBackground
        items={mediaFiles || []}
        onChange={(background) => onBackgroundChange({ slideId: slide.id, background })}
        activeId={slide.background?.id}
      />

      <AudioSourcePicker
        audioSource={slide.audioSource}
        sourceType={slide.sourceType}
        onTypeChange={(sourceType) =>
          onAudioChange({
            slideId: slide.id,
            sourceType,
          })
        }
        onChange={(audioSource) =>
          onAudioChange({
            slideId: slide.id,
            audioSource,
          })
        }
      />
    </>
  );
};
