import {
  Alert,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Avatar } from '../../../openapi';
import { styled } from '../../../utils/styled';
import { AddCircle } from '@mui/icons-material';

const StyledCard = styled(Card)<{ $active: boolean }>`
  flex-shrink: 0;
  max-width: 180px;
  
  ${({ $active, theme }) =>
    $active &&
    `
    border-color: ${theme.palette.primary.main};
  `}

  img {
    max-width: 100%;
    width: auto;
    object-fit: contain;
  }
`;

type AvatarsProps = {
  items?: Avatar[];
  onChange: (id: number) => void;
  value?: number;
};

export const Avatars: React.FC<AvatarsProps> = ({ items, onChange, value }) => {
  const [showSnackBar, setShowSnackbar] = useState<boolean>(false);
  return (
    <>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ marginTop: 6 }}
        onClose={() => {
          setShowSnackbar(false);
        }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="info"
          sx={{ width: '500px' }}
        >
          You can create custom avatar with your appearance and emotions with
          our{' '}
          <a
            target="_blank"
            href="https://spiritme.notion.site/SpiritMe-guide-95771ebeb0454059b8fd48ef7b77ce38"
            rel="noreferrer"
          >
            app
          </a>
          !
        </Alert>
      </Snackbar>
      <Typography variant="h6" mb={2}>
        Choose an avatar
      </Typography>
      <Box overflow="auto">
        <Stack direction="row" spacing={2} py={2} overflow="auto">
          {!items && (
            <>
              <Skeleton variant="rounded" width={100} height={140} />
              <Skeleton variant="rounded" width={100} height={140} />
              <Skeleton variant="rounded" width={100} height={140} />
            </>
          )}
          {items && (
            <>
              <Card style={{ minWidth: 100 }} variant="outlined">
                <CardActionArea
                  sx={{ height: '100%' }}
                  onClick={() => {
                    setShowSnackbar(true);
                  }}
                >
                  <CardContent sx={{ textAlign: 'center' }}>
                    <AddCircle fontSize="large" />
                  </CardContent>
                </CardActionArea>
              </Card>
              {items.map((i) => (
                <StyledCard
                  variant="outlined"
                  key={i.id}
                  $active={value === i.id}
                >
                  <CardActionArea onClick={() => onChange(i.id)}>
                    {i.preview ? (
                      <CardMedia
                        component="img"
                        height="140"
                        image={i.preview}
                        alt={i.name}
                      />
                    ) : (
                      <CardContent>{i.name}</CardContent>
                    )}
                  </CardActionArea>
                </StyledCard>
              ))}
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};
