import { routes } from "../consts/routes";

export const redirect = (path: typeof routes[keyof typeof routes]) => {
    const nextPath = new URL(window.location.href).searchParams.get('next');
    
    if (nextPath) {
        window.location.assign(nextPath)
    } else {
        window.location.assign(path);
    }
}