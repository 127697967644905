import { Configuration, Middleware, TokenApi } from '../openapi';
import jwtDecode from 'jwt-decode';
import { routes } from '../consts/routes';

const basePath = process.env.REACT_APP_API_URL || '';

export const ACCESS_TOKEN_STORAGE_KEY = 'access';
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh';

type TokenKey =
  | typeof ACCESS_TOKEN_STORAGE_KEY
  | typeof REFRESH_TOKEN_STORAGE_KEY;

type JwtToken = {
  token_type: 'access' | 'refresh';
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
};

type StorageToken = {
  token: string;
  expires: number;
};

export const saveToken = (key: TokenKey, token: string) => {
  const data = jwtDecode<JwtToken>(token);

  window.localStorage.setItem(
    key,
    JSON.stringify({
      token,
      expires: data.exp,
    } as StorageToken),
  );
};

export const removeToken = (key: TokenKey) => {
  window.localStorage.removeItem(key);
};

export const getToken = (key: TokenKey) => {
  const token = window.localStorage.getItem(key);

  if (!token) {
    return {
      token: null,
      expires: null,
    };
  }

  return JSON.parse(token) as StorageToken;
};

export const refreshToken = async () => {
  // eslint-disable-next-line no-use-before-define
  const tokenApi = new TokenApi(getConfiguration());

  const { token } = getToken(REFRESH_TOKEN_STORAGE_KEY);

  if (!token) {
    return null;
  }

  const { access } = await tokenApi.tokenRefreshCreate({
    tokenRefreshRequest: { refresh: token },
  });

  saveToken(ACCESS_TOKEN_STORAGE_KEY, access);

  return access;
};

const refreshTokenMiddleware: Middleware = {
  pre: async () => {
    const { expires } = getToken(ACCESS_TOKEN_STORAGE_KEY);

    if (!expires || expires <= Date.now() / 1000) {
      await refreshToken();
    }
  },
};

interface BadRequestErrorConstructor<T = any> {
  message: string;
  type: string; // "NOT_ENOUGH_CREDITS",
  details: T;
}

export class BadRequestError extends Error {
  public message = this.json.message;

  public type = this.json.type;

  public details = this.json.details;

  constructor(private json: BadRequestErrorConstructor) {
    super(json.message);
  }
}

const authMiddleware: Middleware = {
  post: async (context) => {
    if (context.response.status === 401) {
      removeToken(ACCESS_TOKEN_STORAGE_KEY);
      window.location.assign(routes.login);
    }
  },
};

const errorMiddleware: Middleware = {
	post: async (context) => {
		if (!context.response.ok) {
			throw new BadRequestError(await context.response.json());
		}
	},
};

export const getConfiguration = (auth = false) =>
  new Configuration({
    basePath,
    accessToken: auth ? () => getToken(ACCESS_TOKEN_STORAGE_KEY).token || '' : undefined,
    middleware: auth ? [
      authMiddleware,
      refreshTokenMiddleware,
      errorMiddleware
    ] : [errorMiddleware],
  });
