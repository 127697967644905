import React, { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { routes } from '../../consts/routes';
import { EditorPage } from '../pages/Editor/Editor';
import { Login } from '../pages/Login/Login';
import { Registration } from '../pages/Registration/Registration';
import { Gallery } from '../pages/Gallery/Gallery';
import { VerifyEmail } from '../pages/VerifyEmail/VerifyEmail';
import { PublicVideo } from '../pages/PublicVideo/PublicVideo';
import { Pricing } from '../pages/Pricing/Pricing';
import { AuthGuard } from '../AuthGuard/AuthGuard';
import { ConfigProvider } from 'antd';
import { MFASettings } from '../pages/MFASettings/MFASettings';
import { ErrorElement } from './ErrorElement';
import { EditorContextProvider } from '../pages/Editor/EditorContext/EditorContext';
import { ResetPassword } from '../pages/ResetPassword/ResetPassword';
import { RequestNewPassword } from '../pages/RequestNewPassword/RequestNewPassword';
import { CommonLogicBlock } from './CommonLogicBlock';
import { logger } from '../../utils/logger';

const NotFound = () => (
  <div style={{ textAlign: 'center' }}>
    <h1>404 Page not found</h1>
    <a href="/">Go to the main page</a>
  </div>
);

const Redirect: React.FC<{
  to: typeof routes[keyof typeof routes];
}> = ({ to }) => <Navigate to={to} />;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export const lightNewTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#161616',
    },
    secondary: {
      main: '#D2F801',
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorElement />,
    element: <CommonLogicBlock />,
    children: [
      { path: routes.login, element: <Login /> },
      { path: routes.resetPasswordByEmail, element: <RequestNewPassword /> },
      { path: routes.resetPassword, element: <ResetPassword /> },
      { path: routes.registration, element: <Registration /> },
      { path: routes.verifyEmail, element: <VerifyEmail /> },
      { path: routes.publicVideo, element: <PublicVideo /> },
      {
        element: <AuthGuard />,
        children: [
          { path: routes.editor, element: <EditorContextProvider><EditorPage /></EditorContextProvider> },
          { path: routes.mobileEditor, element: <EditorContextProvider><EditorPage isMobile /></EditorContextProvider> },
          { path: routes.gallery, element: <Gallery /> },
          { path: routes.pricing, element: <Pricing /> },
          { path: routes.settings, element: <MFASettings /> },
        ],
      },
      { path: routes.root, element: <Redirect to={routes.login} /> },
      { path: '*', element: <NotFound /> },
    ]
  }
]);

export default function Root() {
  const [currentTheme, setCurrentTheme] = useState(lightTheme);

  useEffect(() => {
    try {
      if (window.localStorage.getItem('beta-dark-theme') === 'true') {
        setCurrentTheme(darkTheme);
      }
      if (window.localStorage.getItem('beta-light-theme') === 'true') {
        setCurrentTheme(lightNewTheme);
      }
    } catch {
      logger("debug", "cannot load beta theme")
    }
  }, []);
  
  return (
    <ThemeProvider theme={currentTheme}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#1976d2',
          },
          components: {
            Tabs: {
              colorSplit: 'rgb(196, 196, 196)',
              borderRadiusLG: 4,
            },
          },
        }}
      >
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
}
