import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, CircularProgress, Stack } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useTtsTaskCreate, useTtsTask } from '../../../apiHooks/useTts';
import { BadRequestError } from '../../../apiHooks/configuration';
import { logger } from '../../../utils/logger';

type TtsPlayerProps = {
  text: string;
  voiceId: number;
};

export const TtsPlayer: React.FC<TtsPlayerProps> = ({ voiceId, text }) => {
  const needUpdate = useRef(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const {
    data: newTaskData,
    mutate: createAudio,
    isLoading: isTaskCreating,
    error,
  } = useTtsTaskCreate({ voiceId, text });
  const { data, isLoading: isTaskLoading } = useTtsTask(newTaskData?.id);

  const isLoading = isTaskCreating || isTaskLoading || isFileLoading;

  const handleClick = () => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else if (needUpdate.current) {
      setIsFileLoading(true);
      needUpdate.current = false;
      createAudio();
    } else {
      audioRef.current?.play();
    }
  };

  const getIcon = () => {
    if (isLoading) {
      return <CircularProgress size={20} />;
    }
    if (isPlaying) {
      return <PauseIcon />;
    }

    return <PlayArrowIcon />;
  };

  useEffect(() => {
    if (data?.result && audioRef.current) {
      audioRef.current.src = data.result;
      try {
        audioRef.current.play();
      } catch {
        logger("debug", "cannot play audio automaticaly")
      }
    }
  }, [data?.result]);

  useEffect(() => {
    const audioRefCurrent = audioRef.current;

    if (!audioRefCurrent) {
      return;
    }

    const handlePlay = () => {
      setIsPlaying(true);
    };
    const handlePause = () => {
      setIsPlaying(false);
    };
    const handleLoad = () => {
      setIsFileLoading(false);
    };

    audioRefCurrent.addEventListener('canplaythrough', handleLoad);
    audioRefCurrent.addEventListener('play', handlePlay);
    audioRefCurrent.addEventListener('pause', handlePause);
    audioRefCurrent.addEventListener('stop', handlePause);

    // eslint-disable-next-line consistent-return
    return () => {
      audioRefCurrent.removeEventListener('canplaythrough', handleLoad);
      audioRefCurrent.removeEventListener('play', handlePlay);
      audioRefCurrent.removeEventListener('pause', handlePause);
      audioRefCurrent.removeEventListener('stop', handlePause);
    };
  }, []);

  useEffect(() => {
    needUpdate.current = true;
  }, [text, voiceId]);

  useEffect(() => {
    if (error) {
      setIsFileLoading(false);
    }
  }, [error]);

  return (
    <>
      <Stack alignItems="flex-start">
        <Button disabled={isLoading} onClick={handleClick} startIcon={getIcon()}>
          Listen generated speech
        </Button>
        {!!error && (
          <Alert severity='error'>
            {(error as BadRequestError)?.message}
          </Alert>
        )}
      </Stack>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} />
    </>
  );
};
