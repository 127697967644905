import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { usePublicVideo } from '../../../apiHooks/usePublicVideo';
import { yellow } from '../../../consts/colors';
import { styled } from '../../../utils/styled';
import { Logo } from '../../icons/Logo';
import { VideoPlayer } from './VideoPlayer';

const Wrap = styled('div')<{ $background: string; }>`
  min-height: 100vh;
  width: 100%;

  video {
    background-size: cover;
  }
`;

const StyledButton = styled('a')`
  position: relative;
  background-color: ${yellow};
  border-radius: 28px;
  height: 56px;
  line-height: 56px;
  padding: 0;
  border: none;
  text-decoration: none;
  color: #000;
  font-weight: 600;
  text-align: center;
  width: 320px;
`;

const LogoLink = styled('a')`
  font-family: "Raleway";
  margin-top: ${({theme}) => theme.spacing(4)};
  margin-bottom: ${({theme}) => theme.spacing(3)};
  font-weight: 700;
  font-size: 26px;
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
`;

export const PublicVideo: React.FC = () => {
  const { id = '' } = useParams();
  const { data } = usePublicVideo(id);

  return (
    <Wrap $background={data?.thumbnail || ''}>
      <Container component="main">
        <Box display="flex" flexDirection="column" alignItems="center">
          <LogoLink href='/'>
            <Logo />
            SpiritMe
          </LogoLink>
          {data?.result && (
            <VideoPlayer
              aspect={(data.resolution.width || 16) / (data.resolution.height || 9)}
              poster={data.thumbnail}
              src={data?.result}
            />
          )}
          <Typography mt={2} mb={2} fontWeight={600}>
            create your video with digital avatar
          </Typography>
          <StyledButton target="_blank" href="https://apps.apple.com/am/app/spiritme/id1615635565">
            get the app
          </StyledButton>
        </Box>
      </Container>
    </Wrap>
  );
};
