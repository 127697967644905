import { AudioSourceRequest, Avatar, Layer, Resolution } from "../../../../openapi";
import { TtsType } from "./reducer";

export enum ActionType {
    INIT = 'INIT',
    SET_RESOLUTION = 'SET_RESOLUTION',
    SET_NAME = 'SET_NAME',
    MOVE_SLIDE = "MOVE_SLIDE",
    SET_SELECTED_SLIDE = "SET_SELECTED_SLIDE",
    DELETE_SLIDE = "DELETE_SLIDE",
    CREATE_SLIDE = "CREATE_SLIDE",
    UPDATE_LAYER = "UPDATE_LAYER",
    UPDATE_BACKGROUND = "UPDATE_BACKGROUND",
    UPDATE_AUDIO = "UPDATE_AUDIO",
    SET_AUTO_EMOTIONS = "SET_AUTO_EMOTIONS",
}

export type Action = {
    type: ActionType.INIT;
    payload: {
        avatar: Avatar;
        voiceId: number;
    }
} | {
    type: ActionType.SET_RESOLUTION;
    payload: Required<Resolution>;
} | {
    type: ActionType.SET_NAME;
    payload: string;
} | {
    type: ActionType.MOVE_SLIDE;
    payload: {
        dragIndex: string | number;
        hoverIndex: string | number;
    }
} | {
    type: ActionType.SET_SELECTED_SLIDE;
    payload: string;
} | {
    type: ActionType.DELETE_SLIDE;
    payload: {
        id: string,
        selectedId: string;
    };
} | {
    type: ActionType.CREATE_SLIDE;
} | {
    type: ActionType.UPDATE_LAYER;
    payload: {
      slideId: string;
      layer: Partial<Layer>;
    }
} | {
    type: ActionType.UPDATE_BACKGROUND;
    payload: {
      slideId: string;
      background: number | undefined;
    };
} | {
    type: ActionType.UPDATE_AUDIO;
    payload: Partial<{
        sourceType: TtsType;
        audioSource: AudioSourceRequest;
    }> & { slideId: string; }
} | {
  type: ActionType.SET_AUTO_EMOTIONS;
  payload: boolean;
};

// type Payload<T extends ActionType> = (Action & { type: T })["payload"];

// function makeAction(type: ActionType, dispatch: React.Dispatch<Action>) {
//     return (payload: (Action & { type: typeof type })["payload"]) => dispatch({ type, payload })
// }

export const Actions = (dispatch: React.Dispatch<Action>) => ({
  init(payload: (Action & { type: ActionType.INIT })["payload"]) {
    dispatch({ type: ActionType.INIT, payload });
  },
  setResolution(payload: (Action & { type: ActionType.SET_RESOLUTION })["payload"]) {
    dispatch({ type: ActionType.SET_RESOLUTION, payload })
  },
  setName(payload: (Action & { type: ActionType.SET_NAME })["payload"]) {
    dispatch({ type: ActionType.SET_NAME, payload })
  },
  moveSlide(payload: (Action & { type: ActionType.MOVE_SLIDE })["payload"]) {
    dispatch({ type: ActionType.MOVE_SLIDE, payload })
  },
  setSelectedSlide(payload: (Action & { type: ActionType.SET_SELECTED_SLIDE })["payload"]) {
    dispatch({ type: ActionType.SET_SELECTED_SLIDE, payload })
  },
  deleteSlide(payload: (Action & { type: ActionType.DELETE_SLIDE })["payload"]) {
    dispatch({ type: ActionType.DELETE_SLIDE, payload })
  },
  createSlide() {
    dispatch({ type: ActionType.CREATE_SLIDE })
  },
  updateLayer(payload: (Action & { type: ActionType.UPDATE_LAYER })["payload"]) {
    dispatch({ type: ActionType.UPDATE_LAYER, payload })
  },
  updateBackground(payload: (Action & { type: ActionType.UPDATE_BACKGROUND })["payload"]) {
    dispatch({ type: ActionType.UPDATE_BACKGROUND, payload })
  },
  updateAudio(payload: (Action & { type: ActionType.UPDATE_AUDIO })["payload"]) {
    dispatch({ type: ActionType.UPDATE_AUDIO, payload })
  },
  setAutoEmotionsMarkup(payload: (Action & { type: ActionType.SET_AUTO_EMOTIONS })["payload"]) {
    dispatch({ type: ActionType.SET_AUTO_EMOTIONS, payload })
  },
});
