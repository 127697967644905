import { useMutation, useQuery, useQueryClient } from 'react-query';
import { routes } from '../consts/routes';
import { AuthApi } from '../openapi';
import { ACCESS_TOKEN_STORAGE_KEY, getConfiguration, getToken, REFRESH_TOKEN_STORAGE_KEY, refreshToken, removeToken } from './configuration';

const AUTH_USER_API_KEY = 'authUser';
const TOKEN_VERIFY_API_KEY = 'tokenVerify';

const authApi = new AuthApi(getConfiguration(true));

export const useAuthUser = (enabled = true) =>
  useQuery(AUTH_USER_API_KEY, ({ signal }) =>
    authApi.authUserRetrieve({ signal }),
    {
      enabled
    }
  );

export const useOtpActivate = () => useMutation(() => authApi.authTotpActivateCreate());

export const useOtpDeactivate = () => useMutation(
  (code: string) => authApi.authTotpDeactivateCreate({ tOTPCodeRequest: { code } })
);

export const useOtpConfirm = () => useMutation(
  (code: string) => authApi.authTotpActivateConfirmCreate({ tOTPCodeRequest: { code } }),
  {
    retry: false
  }
);

export const useTokenVerify = () => {
  const { token, expires } = getToken(ACCESS_TOKEN_STORAGE_KEY);

  return useQuery(TOKEN_VERIFY_API_KEY, async () => {
    const access = !expires || expires <= Date.now() / 1000 ? await refreshToken() : token;

    return authApi.authTokenVerifyCreate({ tokenVerifyRequest: { token: access || '' } });
  });
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  return {
    logout: () => {
      removeToken(ACCESS_TOKEN_STORAGE_KEY);
      removeToken(REFRESH_TOKEN_STORAGE_KEY);
      queryClient.removeQueries(TOKEN_VERIFY_API_KEY);
      window.location.assign(routes.root);
    },
  };
};