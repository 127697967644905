import React, { useRef } from 'react';
import type { TabsProps } from 'antd';
import { Tabs as AntdTabs } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const type = 'DraggableTabNode';
interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  index: React.Key;
  moveNode: (dragIndex: React.Key, hoverIndex: React.Key) => void;
}

const DraggableTabNode = ({
  index,
  children,
  moveNode,
}: DraggableTabPaneProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop<
    { index: React.Key },
    void,
    { isOver?: boolean; dropClassName?: string }
  >({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: 'dropping',
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // get hovered image horizontal middle position
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // determine mouse position
      const clientOffset = monitor.getClientOffset()!;

      // get pixels to the left
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      // only perform the move when the mouse has crossed half of the items height/width
      // are we dragging right or left?
      // const dragRight = dragIndex === hoverIndex - 1;
      // const dragLeft = dragIndex === hoverIndex + 1;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      moveNode(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drop(drag(ref));

  return (
    <div ref={ref} className={isOver ? dropClassName : ''} style={{ opacity }}>
      {children}
    </div>
  );
};

interface DraggableTabsProps extends TabsProps {
  moveSlide: (dragIndex: React.Key, hoverIndex: React.Key) => void;
}

export const DraggableTabs: React.FC<DraggableTabsProps> = ({
  moveSlide,
  ...props
}) => {
  const { items = [] } = props;

  const renderTabBar: TabsProps['renderTabBar'] = (
    tabBarProps,
    DefaultTabBar,
  ) => (
    <DefaultTabBar {...tabBarProps}>
      {(node) => (
        <DraggableTabNode key={node.key} index={node.key!} moveNode={moveSlide}>
          {node}
        </DraggableTabNode>
      )}
    </DefaultTabBar>
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <AntdTabs renderTabBar={renderTabBar} {...props} items={items} />
    </DndProvider>
  );
};
