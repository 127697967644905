import { useMutation, useQuery } from 'react-query';
import { AuthApi, CaptchaRegisterRequest } from '../openapi';
import { getConfiguration } from './configuration';

const api = new AuthApi(getConfiguration());

export const useRegistration = () =>
  useMutation((captchaRegisterRequest: CaptchaRegisterRequest) => api.authRegistrationCreate({ captchaRegisterRequest }));;

export const useVerifyEmail = (key: string) =>
  useQuery(
    [key],
    ({ signal }) =>
      api.authRegistrationVerifyEmailCreate(
        {
          verifyEmailRequest: {
            key,
          },
        },
        { signal },
      ),
    {
      enabled: !!key,
    },
  );

export const useResendVerifyEmail = () => useMutation(
  (email: string) => api.authRegistrationResendEmailCreate({ resendEmailVerificationRequest: { email }})
);
