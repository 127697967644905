import React from 'react';
import { Layout } from '../../Layout/Layout';
import { Button } from '@mui/material';
import { ExternalLink } from '../../ExternalLink/ExternalLink';
import { stripePublishableKey, usePricing } from '../../../apiHooks/usePricing';

export const Pricing: React.FC = () => {
  const { data } = usePricing();
  if (!data) {
    return null;
  }
  const [{ pricingTableId, clientReferenceId }, { portalUrl }] = data;
  return (
    <Layout maxWidth={false}>
      {portalUrl && (
        <Button component={ExternalLink} href={portalUrl}>
          My Billing account
        </Button>
      )}
      {/* @ts-ignore */}
      <stripe-pricing-table
        pricing-table-id={pricingTableId}
        publishable-key={stripePublishableKey}
        client-reference-id={clientReferenceId}
      />
    </Layout>
  );
};
