import { styled } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { yellow } from '../../../consts/colors';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const Wrap = styled('div')<{ $aspect: number; }>`
  position: relative;
  border-radius: 12px;
  width: 600px;
  display: flex;
  aspect-ratio: ${({ $aspect }) => $aspect};
  max-width: 100%;
  max-height: 80vh;
`;

const StyledVideo = styled('video')`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 16px;
`;

const PlayButton = styled('button')`
  position: relative;
  background-color: ${yellow};
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin: auto;
  margin-top: auto;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: 0;
  border: none;

  display: flex;

  svg {
    margin: auto;
    width: 32px;
    height: 32px;
  }
`;

enum AudioState {
  Play,
  Pause,
}

type VideoPlayerProps = {
  aspect: number;
} & React.VideoHTMLAttributes<HTMLVideoElement>;

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ aspect, ...props }) => {
  const [state, setState] = useState(AudioState.Pause);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const handlePlayButton = () => {
    if (state === AudioState.Play) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  };
  const handlePlay = useCallback(() => {
    setState(AudioState.Play);
  }, []);
  const handlePause = useCallback(() => {
    setState(AudioState.Pause);
  }, []);

  return (
    <Wrap $aspect={aspect}>
      <StyledVideo
        ref={videoRef}
        onPlay={handlePlay}
        onPause={handlePause}
        {...props}
      />
      <PlayButton onClick={handlePlayButton}>
        {state === AudioState.Pause && <PlayArrowIcon />}
        {state === AudioState.Play && <PauseIcon />}
      </PlayButton>
    </Wrap>
  );
};
