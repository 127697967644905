import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  Link as StyledLink,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { FormElement } from '../../../typings/form';
import { routes } from '../../../consts/routes';
import { useRegistration } from '../../../apiHooks/useRegistration';
import {
  PRIVACY_POLICY_LINK,
  TERMS_OF_SERVICE_LINK,
} from '../../../consts/links';
import { Link } from '../../Link/Link';
import { useTokenVerify } from '../../../apiHooks/useAuth';
import { Navigate } from 'react-router-dom';
import { Recaptcha, RecaptchaRef } from '../../Recaptcha/Recaptcha';
import { BadRequestError } from '../../../apiHooks/configuration';

type RegistrationFormElement = FormElement<{
  email: string;
  password: string;
  repeatPassword: string;
  agreement: boolean;
}>;

export const Registration: React.FC = () => {
  const recaptchaRef = useRef<RecaptchaRef>(null);
  const [extraError, setExtraError] = useState('');
  const { isSuccess: isLoggedIn } = useTokenVerify();

  const { data, isSuccess, isError, isLoading, error, mutate: register } =
    useRegistration();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const fields = (e.target as RegistrationFormElement).elements;

    if (!fields.agreement?.checked) {
      setExtraError('You need to agree with User agreement');
      return;
    }

    const captcha = await recaptchaRef.current?.getToken();

    if (!captcha) {
      setExtraError('Captcha not solved');
      return;
    }

    setExtraError('');

    register({
      username: fields.email?.value || '',
      email: fields.email?.value || '',
      password1: fields.password?.value || '',
      password2: fields.repeatPassword?.value || '',
      referral: window.Rewardful?.referral || undefined,
      captcha: captcha || ''
    });
  };

  if (isLoggedIn) {
    return <Navigate to={routes.editor} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        marginTop={8}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        {isSuccess ? (
          data.detail
        ) : (
          <Box component="form" onSubmit={handleSubmit} noValidate mt={1} data-rewardful>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="repeatPassword"
              label="Repeat password"
              type="password"
              id="repeatPassword"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox name="agreement" color="primary" />}
              label={
                <Typography mt={2}>
                  I agree with the{' '}
                  <StyledLink target="_blank" href={PRIVACY_POLICY_LINK}>
                    privacy policy
                  </StyledLink>{' '}
                  and{' '}
                  <StyledLink target="_blank" href={TERMS_OF_SERVICE_LINK}>
                    terms of service
                  </StyledLink>
                  .
                </Typography>
              }
            />
            <Recaptcha ref={recaptchaRef} action="register" />

            <Button
              disabled={isLoading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign up
            </Button>
            {isError && (
              <Typography color="error.main" mb={1}>
                {(error as BadRequestError)?.message || 'Something went wrong'}
              </Typography>
            )}
            {extraError && (
              <Typography color="error.main">
                {extraError}
              </Typography>
            )}
            <Grid container>
              <Grid item display="none">
                <Link to="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to={routes.login} variant="body2">
                  Already have an accoint? Sign In
                </Link>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Container>
  );
};
