import React, { PropsWithChildren, useMemo, useReducer } from "react"
import { Actions } from "./actions";
import { defaultState, EditorContextState, reducer } from "./reducer";

type ContextValue = {
  state: EditorContextState,
} & ReturnType<typeof Actions>;

export const EditorContext = React.createContext<ContextValue>({
  state: defaultState,
  ...Actions(() => defaultState)
});

export const EditorContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const actions = useMemo(() => Actions(dispatch), [dispatch]);
    const context = useMemo(() => ({
      state,
      ...actions
    }) as ContextValue, [state, actions]);
    
    return (
        <EditorContext.Provider value={context}>
            {children}
        </EditorContext.Provider>
    )
}