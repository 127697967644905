import QRCode from "qrcode";
import React, { useEffect, useState } from "react";

type QRBlockProps = {
	data: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

export const QRBlock: React.FC<QRBlockProps> = ({ data, ...props }) => {
	const [dataUrl, setDataUrl] = useState("");

	useEffect(() => {
		QRCode.toDataURL(data).then(setDataUrl);
	}, [data]);

	return <img alt={data} src={dataUrl} {...props} />;
};
